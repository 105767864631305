
import { gql } from "@apollo/client";
export const GET_CART_DETAILS = gql`
query GetCartDetails($cart_id: String!) {
        cart(cart_id: $cart_id) {
            items{
                id
                uid
                quantity
                prices {
                    price {
                        value
                        currency
                    }
                    row_total {
                        value
                        currency
                    }
                    total_item_discount {
                        value
                        currency
                    }
                  }
                product{
                  id
                  sku
                  name
                  small_image {
                      url
                  }
              
                }
                ... on ConfigurableCartItem{
                  id
                  configurable_options{
                    id
                    value_id
                    value_label
                    option_label
                  }
                }
              }
              prices {
                grand_total {
                  value
                  currency
                }
              }
              shipping_addresses {
                  available_shipping_methods {
                      carrier_code
                      carrier_title
                      method_code
                      method_title
                  }
              }
              available_payment_methods {
                  code
                  title
              }
            }
      }
      
  `;

  export const SHIPPING_BILLING_INFO = gql`
  query GetMethod($cart_id: String!) {
    cart(cart_id: $cart_id) {
      shipping_addresses {
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
    }
  }
`;

export const SHIPPING_AND_PAYMENT_METHOD = gql`
query GetShipingPaymentMethod($cart_id: String!){
  cart(cart_id: $cart_id) {
    shipping_addresses {
        available_shipping_methods {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
                currency
                value
            }
            price_excl_tax {
                value
                currency
            }
            price_incl_tax {
                value
                currency
            }
        }
    }
    available_payment_methods {
        code
        title
    }
  }
}
`
export const GET_CATEGORY_URL = gql `
query urlResolver($url: String!){
  urlResolver(url:$url) {
      id
      relative_url
      type 
  }
}`


