import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MyAccountPage from "./MyAccountPage";
import BuySidebar from "../Layout/BuySidebar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { FormControl, InputLabel, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

function EditAccountInfo() {
  const { t, i18n } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const isStrongPassword = (password: any) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
    return strongPasswordRegex.test(password);
  };
  // const [formData, setFormData] = useState({
  //     firstname: '',
  //     lastname: '',
  //     email: '',
  //     password: '',
  //     is_subscribed: false,

  // });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [confPassword, setConfPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfPassChange = (event: any) => {
    setConfPassword(event.target.value);
  };

  const [changePassword] = useMutation(
    mutations.customerMutation.CHANGE_PASSWORD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = new FormData();
    form.append("currentPassword", formData.currentPassword);
    form.append("newPassword", formData.newPassword);

    changePassword({
      variables: formData,
    })
      .then((response) => {
        // Handle the response here
        console.log(response);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };
  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "#F0F2F2", mb: 3, p: 1 }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ fontWeight: "600", fontSize: "20px" }}
        >
          {t("buyer_dashboard.editAccountInfo.edit_acnt_info")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#F7F6F6",
          mb: 3,
          border: "1px solid #D2CDCD",
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: "18px", fontWeight: "500" }}
        >
          {t("buyer_dashboard.editAccountInfo.acnt_info")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "background.paper", mb: 3 }}>
        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardContent sx={{ textAlign: "initial" }}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {showChangePassword ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                      {" "}
                      {t("buyer_dashboard.editAccountInfo.old_pwd")}
                      <span style={{ color: "#EA0C0C" }}>*</span>
                    </InputLabel>
                    <TextField
                      autoComplete="given-currentPassword"
                      name="currentPassword"
                      required
                      fullWidth
                      type="password"
                      id="currentPassword"
                      placeholder="Current Password"
                      value={formData.currentPassword}
                      onChange={handleChange}
                      autoFocus
                      sx={{ mb: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                      {t("buyer_dashboard.editAccountInfo.new_pwd")}
                      <span style={{ color: "#EA0C0C" }}>*</span>
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      name="newPassword"
                    placeholder="newPassword"
                      type="password"
                      id="newPassword"
                      autoComplete="off"
                      value={formData.newPassword}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <div>
                      {t("buyer_dashboard.editAccountInfo.pwd_strength")}
                      &nbsp;:&nbsp;
                      {formData.newPassword.length === 0 ? (
                        <span>
                          {t("buyer_dashboard.editAccountInfo.no_pwd")}
                        </span>
                      ) : isStrongPassword(formData.newPassword) ? (
                        <span style={{ color: "green" }}>
                          {t("buyer_dashboard.editAccountInfo.strng_pwd")}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {t("buyer_dashboard.editAccountInfo.weak_pwd")}
                        </span>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                      {t("buyer_dashboard.editAccountInfo.conf_pwd")}
                      <span style={{ color: "#EA0C0C" }}>*</span>
                    </InputLabel>
                    <TextField
                      required
                      fullWidth
                      name="confpassword"
                      placeholder="Confirm Password"
                      type="password"
                      id="confpassword"
                      autoComplete="off"
                      value={confPassword}
                      onChange={handleConfPassChange}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                      {t("buyer_dashboard.editAccountInfo.first_name")}
                      <span style={{ color: "#EA0C0C" }}>*</span>
                    </InputLabel>
                    <TextField
                      autoComplete="given-name"
                      name="firstname"
                      required
                      fullWidth
                      id="firstname"
                      placeholder="First Name"
                      // value={formData.firstname}
                      autoFocus
                      sx={{ mb: 2 }}
                      // onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                      {t("buyer_dashboard.editAccountInfo.last_name")}
                      <span style={{ color: "#EA0C0C" }}>*</span>
                    </InputLabel>
                    <TextField
                      autoComplete="given-name"
                      name="lastname"
                      required
                      fullWidth
                      id="lastname"
                      placeholder="Last Name"
                      // value={formData.firstname}
                      autoFocus
                      sx={{ mb: 2 }}
                      // onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              )}

              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showChangePassword}
                          onChange={(e) =>
                            setShowChangePassword(e.target.checked)
                          }
                        />
                      }
                      label="Change Password"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button variant="contained" disableElevation type="submit">
                      {t("buyer_dashboard.editAccountInfo.save_btn")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
export default EditAccountInfo;
