import React, { ReactNode, useState, useContext, createContext } from "react";
// import './Layout.css'; // Import your CSS stylesheet for styling
import Header from "./Layout/header";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Layout/footer";
import { useMediaQuery } from "@mui/material";
import "./buyer-gen.scss";
import { useEffect } from "react";
import { CountryContext } from "./MainComponent";

// export const mainContext = createContext({
//   cartData: [],
//   setCartData: (data: any) => {},
//   cartCount: 0,
//   setCartCount: (count: any) => {},
//   quest_cart_id: localStorage.getItem("quest_cart_id") || null,
//   set_quest_cart_id: (id: any) => {},
// });

interface MainContentProps {
  children: ReactNode;
  countryCall: (store: any, val: any, load: any) => void;
  isCartId: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCardContent-root": {
      //  background-color: '#fafafa'
      "& .MuiPaper-root": {
        backgroundColor: "#ffffff",
      },
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "0px",
      },
    },
  },
}));

function Layout(
  { isCartId, children, countryCall }: MainContentProps,
  count: any
) {
  const classes = useStyles();
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [quest_cart_id, set_quest_cart_id] = useState(
    localStorage.getItem("quest_cart_id") || null
  );
  // const [availabalCart, setAvailableCart] = useState('')
  // const {quest_cart_id, cartCount, setCartCount} = useContext(mainContext)
  const { country, setCountry } = useContext(CountryContext);
  const [store, setStore] = useState("");
  //   const [country, setCountry] = useState("");
  const [onLoad, setOnLoad] = useState("");
  // const { window, getlCountry } = getCountry;

  const handleCountry = (store: any, val: any, load: any) => {
    setStore(store);
    setOnLoad(load);
    setCountry(val);
  };

  useEffect(() => {
    countryCall(store, country, onLoad);
  }, [store, country, onLoad]);

  const handlefcountry = (val: any) => {
    setCountry(val);
    setStore(val);
  };
  return (
    // <mainContext.Provider
    //   value={{
    //     cartData,
    //     setCartData,
    //     cartCount,
    //     setCartCount,
    //     quest_cart_id,
    //     set_quest_cart_id,
    //   }}
    // >
      <div className={classes.root}>
        <div className="app">
          <header className="header">
            <Header
              isFCountry={country}
              store={store}
              count={count}
              isCartId={isCartId}
            />
          </header>
          <div className="content-wrapper">
            <main className="main-content">{children}</main>
          </div>
            <footer className="footer">
              <Footer
                getCountry={handleCountry}
                // isCountry={country !== undefined ? country : ""}
                callback={handlefcountry}
                store={store}
              />
            </footer>
        </div>
      </div>
    // </mainContext.Provider>
  );
}

export default Layout;
