import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GridUn from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useDebounce } from "use-debounce";
import * as mutations from "../../../operations/mutations";
import "./header.scss";
// import '../../buyer/buyer-gen.scss';
import * as queries from "../../../operations/queries";
import { useMutation } from "@apollo/client";
import SubMenu from "./subMenu";
import Badge from "@mui/material/Badge";
import AddCart from "./addcart";
import { useMediaQuery } from "@mui/material";
import theme from "../../../theme";
import { CountryContext, mainContext } from "../MainComponent";
// import { mainContext } from "../layout";
import CloseIcon from "@mui/icons-material/Close";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1.0),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.9),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("lg")]: {
    marginLeft: theme.spacing(9),
    width: "auto",
  },
  // [theme.breakpoints.down('lg')]: {
  //   marginLeft: theme.spacing(5),
  //   width: 'auto',
  // },
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: alpha(theme.palette.common.black, 1.0),
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: alpha(theme.palette.common.black, 1.0),

    [theme.breakpoints.up("xl")]: {
      width: "70ch !important",
    },
    [theme.breakpoints.down("xl")]: {
      width: "80ch",
    },
    [theme.breakpoints.down("lg")]: {
      width: "36ch",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    //   [theme.breakpoints.up('sm')]: {
    //     width: '30ch',
    //   },
  },
  "&.MuiInputBase-root": {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const drawerWidth = "fit-content";
const settings = [
  // {name: 'Profile', path: ''},
  // {name : 'Account', path : ''} ,
  { name: "Dashboard", path: "/buyerdashboard" },
  { name: "Logout", path: "" },
];
function Header(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { country, setCountry, store, setStore, loading, setLoading } =
    useContext(CountryContext);
  const {
    cartData,
    setCartData,
    cartCount,
    quest_cart_id,
    setCartCount,
    set_quest_cart_id,
  } = useContext(mainContext);
  const token = localStorage.getItem("loggedInCust");
  const loggedInToken = token;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showDesktop, setShowDesktop] = React.useState(false);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (btn: any) => {
    if (btn === "Logout") {
      localStorage.removeItem("loggedInCust");
      localStorage.removeItem("quest_cart_id");
      set_quest_cart_id("");
    }
    setAnchorElUser(null);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {settings.map((setting) => (
        <Link
          to={`${store}${setting.path}`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <MenuItem
            key={setting.name}
            onClick={() => handleCloseUserMenu(setting.name)}
          >
            <Typography textAlign="center">{setting.name}</Typography>
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { window, getCountry } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [collapseIndex, setCollapseIndex] = useState();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [language, setLanguage] = useState("ae_ar");
  const [selectLang, setSelectLanguage] = useState("ae_ar");
  const [selectedStore, setSelectedStore] = useState("ae_ar");
  const [searchText, setSearchText] = useState("");
  // const [loading, setLoading] = useState(true);
  const [debouncedSearchText] = useDebounce(searchText, 1000);
  const [onLoad, setOnLoad] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [directionLang, setDirectionLang] = useState("");

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useMemo(() => {
    if (cartCount) {
      setOpenPopup(true);
    }
  }, [cartData]);

  useEffect(() => {
    if (cartCount) {
      setOpenPopup(true);
    }
  }, [cartData]);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleClick = (index: any) => {
    setOpen(!open);
    setCollapseIndex(index);
  };

  const { data: searchRes } = useQuery(queries.headerQueries.SEARCH_QUERY, {
    variables: { searchText: debouncedSearchText },
    skip: debouncedSearchText === "",
  });
  const { loading: custQueryLoader, data: custQueryDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    if (custQueryDataRes && loggedInToken !== "") {
      set_quest_cart_id(custQueryDataRes?.customerCart?.id);
      localStorage.setItem("quest_cart_id", custQueryDataRes?.customerCart?.id);
    }
  }, [custQueryDataRes]);
  const {
    loading: cartDetailsLoading,
    error: cartDetailsErr,
    data: cartDetailsRes,
  } = useQuery(queries.cartQueries.GET_CART_DETAILS, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  useMemo(() => {
    if (
      cartDetailsRes &&
      cartDetailsRes?.cart &&
      cartDetailsRes?.cart?.items?.length
    ) {
      setCartCount(cartDetailsRes?.cart?.items?.length);
    }
  }, [cartDetailsRes]);

  const handleDefLangChange = () => {
    setSelectedStore(selectedStore);
    if (store === "en" || store.split("_")[1] === "en") {
      let beforeVal: any;
      if (store.indexOf("_") !== -1) {
        beforeVal = store.split("_")[0] + "_ar";
      } else {
        beforeVal = "ar";
      }
      setSelectLanguage(beforeVal);
      // setCountry(beforeVal)
      setStore(beforeVal);
      const path = location.pathname.split("/");
      const route = location.pathname.replace(path[1], beforeVal);
      navigate(`${route}`);
      setLanguage("ar");
      i18n.changeLanguage("ar");
      document.documentElement.setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
      setDirectionLang("ar");
    } else {
      let beforeVal: any;
      if (store.indexOf("_") !== -1) {
        beforeVal = store.split("_")[0] + "_en";
      } else {
        beforeVal = "en";
      }
      setSelectLanguage(beforeVal);
      // setCountry(beforeVal);
      setStore(beforeVal);
      const path = location.pathname.split("/");
      const route = location.pathname.replace(path[1], beforeVal);
      navigate(`${route}`);
      setLanguage("ar");
      i18n.changeLanguage("en");
      document.documentElement.setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
      setDirectionLang("en");
    }
  };

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const parentId = 2;
  const {
    loading: menuLoader,
    data: menuRes,
    refetch,
  } = useQuery(queries.headerQueries.GET_MENUS, {
    variables: { parentId },
    context: {
      headers: customHeader,
    },
  });
  const {
    loading: logoLoader,
    data: logoRes,
    refetch: refetchLogo,
  } = useQuery(queries.headerQueries.GET_LOGO, {
    context: {
      headers: customHeader,
    },
  });
  const { loading: storeLoader, data: storeRes } = useQuery(
    queries.headerQueries.GET_STORES,
    {
      context: {
        headers: customHeader,
      },
    }
  );
  const menuData: any[] = menuRes?.categories?.items;

  const searchTerms = searchRes?.searchTerms;
  let searchData = searchRes?.products;
  const logoData = logoRes?.storeConfig;
  const storeData = storeRes?.getStores;
  const {
    loading: custDetailsLoader,
    error: error,
    data: custDetailsDataRes,
  } = useQuery(queries.customerQueries.GET_CUSTOMER_INFO, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : location?.state
          ? location?.state?.token
          : "",
        Connection: "keep-alive",
      },
    },
  });
  if (
    !location?.state?.isLogin &&
    loggedInToken &&
    error &&
    error?.message == "The current customer isn't authorized."
  ) {
    localStorage.removeItem("loggedInCust");
    localStorage.removeItem("quest_cart_id");
    set_quest_cart_id("");
  }
  const firstName = custDetailsDataRes?.customer?.firstname;
  const lastName = custDetailsDataRes?.customer?.lastname;

  const firstCharacterOfFirstName = firstName ? firstName[0] : "";
  const firstCharacterOfLastName = lastName ? lastName[0] : "";

  const loggedInString = `${firstCharacterOfFirstName}${firstCharacterOfLastName}`;
  useEffect(() => {
    if (!menuLoader && !logoLoader && !storeLoader) {
      setLoading(false);
    }
  }, [menuLoader, logoLoader, storeLoader]);

  useEffect(() => {
    if (country !== selectLang) {
      setSelectedStore(country);
      setSelectLanguage(props.isFCountry);
      setCountry(props.isFCountry);
    }
  }, [props.isFCountry, storeData]);

  useEffect(() => {
    refetch();
    refetchLogo();
  }, [store, refetch, refetchLogo]);

  const logoUrl =
    logoData?.secure_base_media_url + "logo/" + logoData?.header_logo_src;

  const drawer = (
    <Box
      sx={{ textAlign: "center", backgroundColor: "#050505", color: "#ffffff" }}
    >
      {menuData ? (
        <List>
          {menuData.map((item: any, index: any) => {
            return (
              <div key={index} style={{ borderBottom: "1px solid #454545" }}>
                <ListItem key={item.url_key} disablePadding>
                  {/* <Link to={`/${store}/category/${item?.url_key}.html`}> */}
                  <ListItemButton
                    sx={{ textAlign: "left" }}
                    onClick={() => handleClick(index)}
                  >
                    <ListItemText primary={item.name} />
                    {item?.children?.length > 0 ? (
                      open && collapseIndex === index ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                  {/* </Link> */}
                </ListItem>

                {collapseIndex === index &&
                  item.children.map((option: any, index: any) => {
                    return (
                      <Collapse in={open} timeout="auto" unmountOnExit={true}>
                        <List component="div" disablePadding key={item?.id}>
                          <Link
                            to={`/${store}/category/${option?.url_path}.html`}
                            onClick={handleDrawerToggle}
                          >
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText primary={option.name} /> 
                            </ListItemButton>
                          </Link>
                        </List>
                      </Collapse>
                    );
                  })}
              </div>
            );
          })}
        </List>
      ) : (
        ""
      )}
    </Box>
  );
  return (
    <>
      {!loading && (
        <div className="page-header">
          <div className="header-content">
            <Box sx={{ flexGrow: 1, mb: { xs: "5px", md: "0px" } }}>
              <AppBar position="static">
                <Toolbar sx={{ display: { md: "flex", xs: "block" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: { md: 0, xs: 2 },
                      width: "100%",
                    }}
                  >
                    <IconButton
                      className="toggle-nav-btn"
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 1, display: { md: "none" }, color: "#fff" }}
                    >
                      {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
                    </IconButton>
                    <div className="logo-nav">
                      <div className="logo-container">
                        <Link
                          className="brand-link"
                          to="/"
                          style={{ height: "100%", display: "flex" }}
                        >
                          <img
                            src={logoUrl}
                            alt="React Logo"
                            className="logo"
                          />
                          {/* <img src={logoData?.header_logo_src} alt="React Logo" className="logo" /> */}
                        </Link>
                      </div>
                    </div>
                    <Search
                      className="search-nav"
                      sx={{
                        display: { xs: "none", md: "block" },
                        marginLeft: "10px",
                      }}
                    >
                      <SearchIconWrapper className="search-icon">
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder={t("main.search-placeholder")}
                        inputProps={{ "aria-label": "search" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoComplete="true"
                      />
                      {showSearch && searchData?.items?.length > 0 ? (
                        <div className="search-result">
                          <div className="search-term-box">
                            <p className="sb-title">
                              {t("header.search-term")}
                            </p>
                            {searchTerms ? (
                              <GridUn
                                container
                                columns={{ xs: 4, sm: 8, md: 12 }}
                              >
                                {Array.from(Array(searchTerms?.length)).map(
                                  (_, index) => (
                                    <GridUn xs={2} sm={4} md={4} key={index}>
                                      {/* <Link to={`/details/${productdata.sku}`}> */}
                                      {/* <Link to={`/details`}> */}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          padding: "5px",
                                          border: "1px solid #DDDADA",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                          }}
                                        >
                                          {searchTerms[index]?.title}
                                        </p>
                                        <p
                                          style={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                          }}
                                        >
                                          {searchTerms[index]?.num_results}
                                        </p>
                                      </Box>
                                      {/* </Link> */}
                                    </GridUn>
                                  )
                                )}
                              </GridUn>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="search-prod-box">
                            <p className="sb-title">Products</p>
                            <GridUn
                              container
                              spacing={{ xs: 1 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {Array.from(Array(searchData?.items?.length)).map(
                                (_, index) => (
                                  <GridUn xs={12} key={index}>
                                    <div onClick={() => setShowSearch(false)}>
                                      <Link
                                        to={`/${store}/${searchData?.items[index].url_key}.html`}
                                      >
                                        <Card
                                          sx={{
                                            display: "flex",
                                            boxShadow: "none",
                                            border: "1px solid #DDDADA",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                          >
                                            <CardMedia
                                              component="img"
                                              sx={{
                                                width: "100%",
                                                border: "1px solid #e5e5e5",
                                                height: "100%",
                                                objectFit: "cover",
                                              }}
                                              image={
                                                isSmallScreen
                                                  ? searchData?.items[index]
                                                      ?.small_image?.url
                                                  : searchData?.items[index]
                                                      .image?.url
                                              }
                                              alt="Live from space album cover"
                                            />
                                          </div>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <CardContent
                                              sx={{
                                                flex: "1 0 auto",
                                                padding: "3px 8px",
                                                paddingBottom: "0px !important",
                                              }}
                                            >
                                              <Typography
                                                component="div"
                                                variant="subtitle1"
                                              >
                                                {searchData.items[index].name}
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="h6"
                                              >
                                                {
                                                  searchData.items[index]
                                                    .price_range.minimum_price
                                                    .regular_price.currency
                                                }
                                                {
                                                  searchData.items[index]
                                                    .price_range.minimum_price
                                                    .regular_price.value
                                                }
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="h6"
                                              >
                                                {country === "IN"
                                                  ? t("main.inclusive_of_gst")
                                                  : t("main.inclusive_of_vat")}
                                              </Typography>
                                            </CardContent>
                                          </Box>
                                        </Card>
                                      </Link>
                                    </div>
                                  </GridUn>
                                )
                              )}
                            </GridUn>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Search>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      sx={{ display: { xs: "flex" }, color: "#fff" }}
                      className="right-icon-box"
                    >
                      <Stack
                        direction="row"
                        divider={
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ display: { xs: "none", sm: "block" } }}
                          />
                        }
                        spacing={{ sm: 2, xs: 1 }}
                        sx={{ height: "20px", alignSelf: "center" }}
                      >
                        <div className="language-box icon-btn-wrap">
                          {selectLang !== "in" ? (
                            <Button
                              sx={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                textTransform: "unset",
                              }}
                              size="medium"
                              onClick={(ev) => handleDefLangChange()}
                            >
                              {t("main.language")}
                            </Button>
                          ) : (
                            ""
                          )}
                          {/* <div className="flag-wrap" style={{ display: "flex" }}>
                        {storeData && storeData.map((locale: any) => {
                          if(locale.store_code === selectedStore)
                          {
                           return( <Box sx={{display:'flex', alignItems:'center', gap:'20px'}}>
                            <div className="flag-img">
                            <img
                                     src={locale?.flag}
                                     alt="flag"
                                     className="img-cover"
                                   />
                                   </div>
                                   <span>{locale?.country}</span>

                            </Box>
                           );
                          }
                         
                        }
                         
                        )}
                      </div> */}
                          <div
                            className="flag-wrap"
                            style={{ display: "flex" }}
                          >
                            {storeData ? (
                              <Select
                                value={selectedStore}
                                disabled
                                // onChange={(e) => handleChangeLang(e)}
                                className="lang-control"
                                inputProps={{
                                  "aria-label": "Language selection label",
                                }}
                              >
                                {storeData.map((locale: any) => (
                                  <MenuItem
                                    key={locale?.store_code}
                                    value={locale?.store_code}
                                    className="lang-items"
                                  >
                                    <div className="store-selection-drop">
                                      <div className="flag-img">
                                        <img
                                          src={locale?.flag}
                                          alt="flag"
                                          className="img-cover"
                                        />
                                      </div>
                                    </div>
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="icon-btn-wrap">
                          {loggedInToken !== "" && loggedInToken !== null ? (
                            <IconButton
                              // classes={{ label: classes.iconButton }}
                              size="medium"
                              aria-label="account of current user"
                              aria-controls={menuId}
                              aria-haspopup="true"
                              onClick={handleOpenUserMenu}
                              color="inherit"
                            >
                              <Box
                                sx={{
                                  display: {
                                    md: "flex",
                                    height: "32px",
                                    width: "32px",
                                    backgroundColor: "#cbe5f8",
                                    borderRadius: "100%",
                                    padding: "14px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  },
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "#0e5284",
                                  }}
                                >
                                  {loggedInString}
                                </span>
                              </Box>
                              {/* <PersonOutlineOutlinedIcon /> */}
                            </IconButton>
                          ) : (
                            <>
                              <Link to={`/${store}/login`}>
                                <Box
                                  sx={{
                                    display: { xs: "none", md: "flex" },
                                    fontSize: "15px",
                                    marginRight: "5px",
                                  }}
                                >
                                  {t("main.login")}
                                </Box>
                                <PersonOutlineOutlinedIcon />
                              </Link>
                            </>
                          )}

                          {renderMenu}
                        </div>
                        <div className="icon-btn-wrap">
                          <IconButton
                            size="medium"
                            ref={setAnchor}
                            onClick={() => setOpenPopup((val) => !val)}
                            // onClick={handleAddToCart}
                            aria-label="shopping cart"
                            color="inherit"
                            type="button"
                            sx={{ p: 0 }}
                          >
                            <Box
                              sx={{
                                display: {
                                  xs: "none",
                                  md: "flex",
                                  fontSize: "16px",
                                  marginRight: "6px",
                                },
                                fontSize: "15px",
                                marginRight: "5px",
                              }}
                            >
                              {t("main.cart")}
                            </Box>

                            <Badge badgeContent={cartCount} color="primary">
                              <ShoppingCartOutlinedIcon />
                            </Badge>
                          </IconButton>
                          <AddCart
                            isOpenPopup={openPopup}
                            isAnchor={anchor}
                            handleClosePopup={handleClosePopup}
                            isCartId={quest_cart_id}
                          />
                        </div>
                      </Stack>
                    </Box>
                  </Box>

                  <Search
                    className="search-nav"
                    sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
                  >
                    <SearchIconWrapper className="search-icon">
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder={t("main.search-placeholder")}
                      inputProps={{ "aria-label": "search" }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      autoComplete="true"
                    />
                    {showSearch && searchData?.items?.length > 0 ? (
                      <div className="search-result">
                        <div className="search-term-box">
                          <p className="sb-title">Search Terms</p>
                          {searchTerms ? (
                            <GridUn
                              container
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {Array.from(Array(searchTerms?.length)).map(
                                (_, index) => (
                                  <GridUn xs={2} sm={4} md={4} key={index}>
                                    {/* <Link to={`/details/${productdata.sku}`}> */}
                                    {/* <Link to={`/details`}> */}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "5px",
                                        border: "1px solid #DDDADA",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        {searchTerms[index]?.title}
                                      </p>
                                      <p
                                        style={{
                                          marginTop: 2,
                                          marginBottom: 2,
                                        }}
                                      >
                                        {searchTerms[index]?.num_results}
                                      </p>
                                    </Box>
                                    {/* </Link> */}
                                  </GridUn>
                                )
                              )}
                            </GridUn>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="search-prod-box">
                          <p className="sb-title">Products</p>
                          <GridUn
                            container
                            spacing={{ xs: 1 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                          >
                            {Array.from(Array(searchData?.items?.length)).map(
                              (_, index) => (
                                <GridUn xs={12} key={index}>
                                  <div onClick={() => setShowSearch(false)}>
                                    <Link
                                      to={`/${store}/${searchData?.items[index].url_key}.html`}
                                    >
                                      <Card
                                        sx={{
                                          display: "flex",
                                          boxShadow: "none",
                                          border: "1px solid #DDDADA",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "80px",
                                            width: "80px",
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            sx={{
                                              width: "100%",
                                              border: "1px solid #e5e5e5",
                                              height: "100%",
                                              objectFit: "cover",
                                            }}
                                            image={
                                              isSmallScreen
                                                ? searchData?.items[index]
                                                    ?.small_image?.url
                                                : searchData?.items[index].image
                                                    ?.url
                                            }
                                            alt="Live from space album cover"
                                          />
                                        </div>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <CardContent
                                            sx={{
                                              flex: "1 0 auto",
                                              padding: "3px 8px",
                                              paddingBottom: "0px !important",
                                            }}
                                          >
                                            <Typography
                                              component="div"
                                              variant="subtitle1"
                                            >
                                              {searchData.items[index].name}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="h6"
                                            >
                                              {
                                                searchData.items[index]
                                                  .price_range.minimum_price
                                                  .regular_price.currency
                                              }
                                              {
                                                searchData.items[index]
                                                  .price_range.minimum_price
                                                  .regular_price.value
                                              }
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              color="text.secondary"
                                              component="h6"
                                            >
                                              {country === "IN"
                                                ? t("main.inclusive_of_gst")
                                                : t("main.inclusive_of_vat")}
                                            </Typography>
                                          </CardContent>
                                        </Box>
                                      </Card>
                                    </Link>
                                  </div>
                                </GridUn>
                              )
                            )}
                          </GridUn>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Search>
                </Toolbar>
              </AppBar>

              <nav>
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                  className="sidebar-drawer"
                >
                  {drawer}
                </Drawer>
              </nav>
            </Box>
            <SubMenu menuData={menuData} />
          </div>
        </div>
      )}
    </>
  );
}
export default Header;
