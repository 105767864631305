import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MyAccountPage from "./MyAccountPage";
import BuySidebar from "../Layout/BuySidebar";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
function AddressBook() {
  const { t, i18n } = useTranslation();
  let countryCode: any;
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { loading: custDetailsLoader, data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  custDetailsDataRes?.customer?.addresses.map((data: any, index: any) => {
    if (data?.default_shipping === true) {
      countryCode = data?.country_code;
    } else if (data?.default_billing === true) {
      countryCode = data?.country_code;
    }
  });
  return (
    <>
      <div className="address_book">
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F0F2F2",
            mb: 3,
            p: 1,
            textAlign: "initial",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            sx={{ fontWeight: "600", fontSize: "20px" }}
          >
            {t("buyer_dashboard.addressBook.addr_book")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F7F6F6",
            mb: 3,
            border: "1px solid #D2CDCD",
            p: 1,
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontSize: "18px", fontWeight: "500" }}
          >
            {t("buyer_dashboard.addressBook.primary_addr")}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
          {custDetailsDataRes?.customer?.addresses.map(
            (data: any, index: any) =>
              data?.default_shipping === true ? (
                <Card variant="outlined">
                  <CardContent sx={{ textAlign: "initial" }}>
                    <Typography
                      sx={{ fontSize: 18, fontWeight: 500 }}
                      gutterBottom
                    >
                      {t("buyer_dashboard.addressBook.default_bill_addr")}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontSize: 18, fontWeight: 400 }}
                    >
                      {data?.firstname}&nbsp;{data?.lastname}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontSize: 18, fontWeight: 400 }}
                    >
                      {data?.street[0]}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontSize: 18, fontWeight: 400 }}
                    >
                      {data?.city},
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontSize: 18, fontWeight: 400 }}
                    >
                      {data?.country_code}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ fontSize: 18, fontWeight: 400 }}
                    >
                      Mob&nbsp;:&nbsp;{data?.telephone}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ display: "flex", gap: "25px" }}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={2}
                    >
                      <Item>
                        <Link to="edit-address-book/edit-shipping">
                          <Button size="small">Change Shipping Address</Button>
                        </Link>
                        {/* {changePassRes && <div>Password changed successfully</div>}
                            {changePassError && <div>Error: {changePassError.message}</div>} */}
                      </Item>
                    </Stack>
                  </CardActions>
                </Card>
              ) : (
                ""
              )
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F7F6F6",
            mb: 3,
            border: "1px solid #D2CDCD",
            display: "flex",
            gap: "25px",
            p: 2,
          }}
        >
          <Typography variant="h6" component="h6">
            {t("buyer_dashboard.addressBook.change_ship_addr")}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
          <Card variant="outlined" sx={{ minWidth: "100%" }}>
            <CardContent sx={{ textAlign: "initial" }}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                {t("buyer_dashboard.addressBook.no_addr_entry")}
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ width: "100%", bgcolor: "#fff", mb: 3 }}>
          <Link to="edit-address-book/add-address">
            <Button
              variant="contained"
              disableElevation
              sx={{
                my: 2,
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "500",
                padding: "6px 34px",
              }}
            >
              {t("buyer_dashboard.addressBook.add_address")}
            </Button>
          </Link>
        </Box>
      </div>
    </>
  );
}
export default AddressBook;
