import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MyAccountPage from "./MyAccountPage";
import BuySidebar from "../Layout/BuySidebar";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

function MyWishList() {
  const { t } = useTranslation();
  return (
    <>
      <div className="wishlist-content">
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F0F2F2",
            mb: 3,
            p: 1,
            textAlign: "initial",
          }}
        >
          <Typography
            variant="h5"
            component="h5"
            sx={{ fontWeight: "600", fontSize: "20px" }}
          >
            {t("buyer_dashboard.myWishList.wishlist")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F7F6F6",
            mb: 3,
            p: 0,
          }}
        >
          <Alert severity="warning">
            {" "}
            {t("buyer_dashboard.myWishList.alert_msg")}
          </Alert>
        </Box>
      </div>
    </>
  );
}
export default MyWishList;
