import React, { useEffect } from 'react';

const CCAvenueForm = (props:any) => {    
    const {access_code, encRequest, integration_type} = props?.data?.fields[0];

    useEffect(() => {
        const submitForm = async () => {
          try {
            const form: any = document.getElementById('ccavenueForm');
            form?.submit();
          } catch (error) {
            console.error('Error submitting form:', error);
          }
        };
        submitForm();
      }, []); 
  return (
    <form id="ccavenueForm"
    action={props?.data?.url}
    method="post">
        <input type="hidden" name="integration_type" value={integration_type}  />
        <input type="hidden" name="encRequest" value={encRequest}  />
        <input type="hidden" name="access_code" value={access_code}  />
    </form>
  );
};

export default CCAvenueForm;
