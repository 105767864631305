import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";
import { useContext } from "react";
import { CountryContext } from "../../container/buyer/MainComponent";
import { useLocation } from "react-router-dom";
import './payment.scss';

function Payment() {
  const { store } = useContext(CountryContext);
  const location = useLocation();
  const identifier = location?.pathname?.split("/");
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const { data: paymentData } = useQuery(queries.cmsQueries.GET_PAYMENT_PAGE, {
    variables: { identifier: identifier[identifier.length - 1] },
    context: {
      headers: customHeader,
    },
  });
  let paymentHtml = paymentData?.cmsPage?.content;
  const str = paymentHtml?.replaceAll("&lt;", "<");
  const finalStr = str?.replaceAll("&gt;", ">");

  return (
    <div className="PaymentWrapper">
      <div dangerouslySetInnerHTML={{ __html: finalStr }}></div>
    </div>
  );
}

export default Payment;
