import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
function Newsletter() {
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const [subscribed, setSubscribed] = useState(false);
  const { loading: custDetailsLoader, data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleToggle = () => {
    setSubscribed(!subscribed);
  };

  const [
    subscribeToNewsletter,
    { loading: newsletterLoading, error: newsletterError, data: newsletterRes },
  ] = useMutation(mutations.customerMutation.SUBSCRIBE_EMAIL_TO_NEWSLETTER, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  const handleSubscription = async () => {
    try {
      const response = await subscribeToNewsletter({
        variables: {
          email: custDetailsDataRes?.customer?.email,
        },
      });
      const status = response.data.subscribeEmailToNewsletter.status;
    } catch (err) {}
  };
  const [updateCustomerSubscribe] = useMutation(
    mutations.customerMutation.UPDATE_CUSTOMER_SUBSCRIBE_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleUpdateCustomerSubscribe = async () => {
    try {
      const { data } = await updateCustomerSubscribe({
        variables: {
          input: {
            is_subscribed: subscribed,
          },
        },
      });

      const updatedCustomerSubscribe = data.updateCustomer.customer;
    } catch (error) {}
  };
  const handleSubscriptionData = async () => {
    if (loggedInToken !== "") {
      // await handleUpdateCustomerSubscribe()
      if (subscribed === false) {
        await handleUpdateCustomerSubscribe();
      } else {
        await handleSubscription();
      }
    } else {
      await handleSubscription();
    }
  };

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent className="newsletter_subscription">
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F0F2F2",
              mb: 4,
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {t("buyer_dashboard.newsletter.newsletter_subscription")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F7F6F6",
              mb: 4,
              border: "1px solid #D2CDCD",
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "18px", fontWeight: "500" }}
            >
              {t("buyer_dashboard.newsletter.subscription_option")}
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={subscribed}
                  onChange={handleToggle}
                  name="subscription"
                />
              }
              label="Allow remote shopping assistance"
            />
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <Button
              variant="contained"
              disableElevation
              onClick={handleSubscriptionData}
              sx={{
                my: 2,
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "500",
                padding: "6px 34px",
              }}
              //  onClick={handleUpdateCustomerSubscribe}
            >
              {t("buyer_dashboard.newsletter.save_btn")}
            </Button>
            {newsletterLoading && (
              <p>{t("buyer_dashboard.newsletter.loading")}.</p>
            )}
            {newsletterError && (
              <p>
                {t("buyer_dashboard.newsletter.err")} {newsletterError.message}
              </p>
            )}
            {newsletterRes && (
              <p>
                {t("buyer_dashboard.newsletter.status")}{" "}
                {newsletterRes.subscribeEmailToNewsletter.status}
              </p>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
export default Newsletter;
