import React from 'react';
import "./loadingContext.css"
import { Height } from '@mui/icons-material';

const GlobalLoader = (props:any) => {  
  return (
    <>
    <div className='overlay' style={{ height: props.type === "Home" ? "100vh" : "8vh" }}>
    <img src={require('../../../assets/image/loader-img.gif')} alt='Loader' className="custom_loader"/>
    </div>
    
    </>
  );
};

export default GlobalLoader;