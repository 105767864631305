import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GridUn from "@mui/material/Unstable_Grid2";
import BoxUn from "@mui/material/Box";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./home.scss";
import CarouselSlider from "../../../components/CarouselSlider/CarouselSlide";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
import theme from "../../../theme";
import { CountryContext, mainContext } from "../MainComponent";
// import { mainContext } from "../layout";
import LoadingContext from "../Layout/loader";

interface HomeProps {
  store: string;
  isCountry: string;
  onLoad: boolean;
}
function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const responsive_one: any = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
const responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1536 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1200 },
    items: 10,
    slidesToSlide: 3,
  },
  laptop: {
    breakpoint: { max: 1200, min: 900 },
    items: 6,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 600 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 4,
    slidesToSlide: 1,
  },
};

const shop_now_responsive: any = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function Home({ onLoad, isCountry }: HomeProps) {
  const { t } = useTranslation();
  const { country, setCountry, store, setStore, setLoading, loading } =
    useContext(CountryContext);
  const title = [
    "main.cap-title",
    "main.newar-title",
    "main.berista-title",
    "main.aceesories-title",
    "main.cp-title",
    "main.staff-title",
    "main.office-title",
  ];
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const {
    loading: sliderLoad,
    data: sliderRes,
    refetch,
  } = useQuery(queries.homeQueries.GET_TOPSLIDER, {
    context: {
      headers: customHeader,
    },
  });

  const {
    loading: catLoader,
    data: catRes,
    refetch: refetchCatData,
  } = useQuery(queries.homeQueries.GET_CATEGORY_SLIDER, {
    variables: {categoryId: "2"},
    context: {
      headers: customHeader,
    },
  });
  const {
    loading: tPromoLoader,
    data: tPromoRes,
    refetch: refetchTopPromo,
  } = useQuery(queries.homeQueries.GET_TOPPROMOTION, {
    context: {
      headers: customHeader,
    },
  });
  const {
    loading: btPromoLoader,
    data: btPromoRes,
    refetch: refetchBottomPromo,
  } = useQuery(queries.homeQueries.GET_BOTOOMPROMOTION, {
    context: {
      headers: customHeader,
    },
  });
  const {
    loading: brandLoader,
    data: brandRes,
    refetch: refetchBrand,
  } = useQuery(queries.homeQueries.GET_BRAND, {
    context: {
      headers: customHeader,
    },
  });
  const { loading: storeConfLoader, data: storeConfRes } = useQuery(
    queries.homeQueries.GET_STORECONFIG,
    {
      context: {
        headers: customHeader,
      },
    }
  );

  useEffect(() => {
    localStorage.getItem("country");
    if (store !== "") {
      setCountry(country);
      refetch();
      refetchCatData();
      refetchTopPromo();
      refetchBottomPromo();
      refetchBrand();
    }
  }, [
    onLoad,
    isCountry,
    country,
    store,
    refetch,
    refetchCatData,
    refetchTopPromo,
    refetchBottomPromo,
    refetchBrand,
  ]);

  const sliderData = sliderRes?.getData;
  const catData = catRes?.getSubCategoriesData;
  const tPromoData = tPromoRes?.getData;
  const brandData = brandRes?.getData;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktopScreen = useMediaQuery("(min-width: 1200px)");

  let btPromoData: any;

  if (btPromoRes?.getData) {
    let bPromoData = JSON.parse(JSON.stringify(btPromoRes?.getData));
    btPromoData = bPromoData;
    if (btPromoData) {
      const imgData = btPromoData.filter((i: any) => i.image !== "");
      const n = 4;
      const result = imgData.slice(0, n);
      btPromoData = result;
      btPromoData.forEach((element: any, index: any) => {
        if (index === 2) {
          element.rows = 2;
          element.cols = 1;
        } else if (index === 3) {
          element.cols = 2;
          element.rows = 1;
        }
      });
    }
  }

  useEffect(() => {
    if (
      !sliderLoad &&
      !catLoader &&
      !tPromoLoader &&
      !btPromoLoader &&
      !brandLoader &&
      !storeConfLoader
    ) {
      setLoading(false);
    }
  }, [
    sliderLoad,
    catLoader,
    tPromoLoader,
    btPromoLoader,
    brandLoader,
    storeConfLoader,
  ]);

  return (
    <>
      {loading ? (
        <LoadingContext type="Home" />
      ) : (
        <>
          <div className="home-wrapper">
            <Box>
              <div className="top-slider -hero-slider">
                {sliderData ? (
                  <Carousel
                    className="top-offer-slider"
                    responsive={responsive_one}
                    swipeable={true}
                    autoPlay={false}
                    transitionDuration={500}
                    autoPlaySpeed={5000}
                    arrows={true}
                    infinite={true}
                    centerMode={false}
                    ssr={true}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    slidesToSlide={1}
                  >
                    {sliderData.map((data: any, index: any) => {
                      return (
                        <Link to={`/${store}/${data?.link}`}>
                          <div
                            key={index}
                            className="image-item"
                            style={{
                              backgroundImage: `url(${
                                isSmallScreen ? data?.image_mobile : data?.image
                              })`,
                            }}
                          ></div>
                        </Link>
                      );
                    })}
                  </Carousel>
                ) : (
                  ""
                )}
              </div>
            </Box>
            <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{ maxWidth: "100%", boxShadow: 0 }}
                    className="category-home-grid"
                  >
                    <CardHeader
                      className="section-title"
                      style={{ paddingTop: 0 }}
                      titleTypographyProps={{ variant: "h5" }}
                      title={t("main.category-title")}
                    />
                    <CardContent sx={{ py: 0 }}>
                      {catData ? (
                        <Carousel
                          className="category-home-grid-slider"
                          responsive={responsive}
                          swipeable={true}
                          autoPlay={false}
                          centerMode={false}
                          arrows={false}
                          infinite={true}
                          ssr={true}
                          showDots={false}
                          slidesToSlide={1}
                          deviceType={""}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                          {catData.map((data: any, index: any) => {
                            return (
                              <div className="category-box">
                                <Link to={`/${store}/category/${data?.url_key}`}>
                                  <div className="category-box-img">
                                    <img
                                      className="img-cover"
                                      draggable={false}
                                      style={{ position: "relative" }}
                                      src={data.image}
                                      alt={data.name}
                                    />
                                  </div>
                                  <div className="category-box-title">
                                    <span>
                                      {data.name}{" "}
                                      {isSmallScreen
                                        ? data.image_mobile
                                        : data.image_mobile}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </Carousel>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <CarouselSlider
              title={t("main.top-sell-title")}
              iscountry={country !== undefined ? country : ""}
            />
            {/* shop now banner section slider */}
            <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{ maxWidth: "100%", boxShadow: 0 }}
                    className="shop-now-grid"
                  >
                    <CardContent
                      style={{ paddingBottom: 0, backgroundColor: "#fafafa" }}
                    >
                      {tPromoData ? (
                        <Carousel
                          className="shop-now-grid-slider"
                          responsive={shop_now_responsive}
                          swipeable={isSmallScreen ? true : false}
                          draggable={isSmallScreen ? true : false}
                          autoPlay={false}
                          centerMode={false}
                          arrows={false}
                          infinite={isSmallScreen ? true : false}
                          partialVisible={isSmallScreen ? true : false}
                          showDots={false}
                          slidesToSlide={1}
                          deviceType={""}
                        >
                          {tPromoData.map((shopNowOffer: any) => {
                            return (
                              <div
                                className="shop-now-box"
                                style={{ height: "100%" }}
                              >
                                <div
                                  className="shop-now-box-img"
                                  style={{ height: "100%" }}
                                >
                                  <img
                                    className="img-cover"
                                    draggable={false}
                                    style={{ position: "relative" }}
                                    src={
                                      isSmallScreen
                                        ? shopNowOffer?.image_mobile
                                        : shopNowOffer?.image
                                    }
                                    alt={shopNowOffer.name}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            {/* Brewers section slider */}
            <CarouselSlider
              title={t("main.brewers-title")}
              iscountry={country !== undefined ? country : ""}
            />
            {/* masonary section slider */}
            <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{ maxWidth: "100%", boxShadow: 0 }}
                    className="brewers-grid jeb-cust-slide"
                  >
                    <CardContent>
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                      >
                        <Grid item xs={12}>
                          {btPromoData ? (
                            <ImageList
                              sx={{
                                height: "100%",
                                width: "100%",
                                overflow: "hidden",
                                my: 0,
                              }}
                              variant="quilted"
                              cols={3}
                              rowHeight={isSmallScreen ? 100 : 300}
                              gap={18}
                            >
                              {btPromoData.map((item: any) => (
                                <ImageListItem
                                  sx={{ position: "relative" }}
                                  key={item?.image}
                                  cols={isSmallScreen ? 3 : item.cols || 1}
                                  rows={isSmallScreen ? 3 : item.rows || 1}
                                >
                                  <img
                                    {...srcset(
                                      isSmallScreen
                                        ? item?.image_mobile
                                        : item?.image,
                                      isSmallScreen ? 100 : 300,
                                      item.rows,
                                      item.cols
                                    )}
                                    alt={item.name}
                                    loading="lazy"
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            {title?.map((ele: any, i: any) => {
              return (
                <>
                  <CarouselSlider
                    title={t(ele)}
                    iscountry={country !== undefined ? country : ""}
                  />
                </>
              );
            })}
            {/* brand section slider */}
            <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{ maxWidth: "100%", boxShadow: 0 }}
                    className="brand-grid"
                  >
                    <div className="section-title">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="span"
                        color="text.dark"
                      >
                        {t("main.brand-title")}
                      </Typography>
                      <Button sx={{ mr: 2 }} variant="outlined">
                        {t("main.view-all-prod")}
                      </Button>
                    </div>
                    <CardContent sx={{ backgroundColor: "#fafafa" }}>
                      <BoxUn sx={{ flexGrow: 1, p: 2 }}>
                        {brandData ? (
                          <GridUn
                            container
                            spacing={2}
                            sx={{
                              "--Grid-borderWidth": "1px",
                              borderTop:
                                isDesktopScreen && brandData.length < 8
                                  ? "none"
                                  : "var(--Grid-borderWidth) solid",
                              borderLeft: "var(--Grid-borderWidth) solid",
                              borderColor: "divider",
                              "& > div": {
                                borderRight: "var(--Grid-borderWidth) solid",
                                borderBottom: "var(--Grid-borderWidth) solid",
                                borderColor: "divider",
                              },
                            }}
                          >
                            {[...Array(brandData.length)].map((_, index) => (
                              <GridUn
                                sx={{
                                  borderTop:
                                    isDesktopScreen && brandData.length < 8
                                      ? "var(--Grid-borderWidth) solid"
                                      : "none",
                                }}
                                key={index}
                                {...{ xs: 4, sm: 2, md: 1.5, lg: 1 }}
                                minHeight={110}
                              >
                                <div className="brand-img-box">
                                  <Link
                                    to={`/${store}/category/${brandData[index].link}`}
                                  >
                                    <img
                                      src={
                                        isSmallScreen
                                          ? brandData[index].image_mobile
                                          : brandData[index].image
                                      }
                                      alt={brandData[index].name}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Link>
                                </div>
                              </GridUn>
                            ))}
                          </GridUn>
                        ) : (
                          ""
                        )}
                      </BoxUn>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}
      ;
    </>
  );
}

export default Home;
