
import { gql } from "@apollo/client";

export const ADD_BILLING_ADDR = gql`
mutation addBillingAddr($firstname: String!, $lastname: String!, $company: String!, $street: [String]!, $city: String!, $region: String!,$regionId: Int!,
    $postCode: String!, $country_code: String!, $telephone: String!, $save_in_address_book: Boolean!, $cartId: String!
  ) {
  setBillingAddressOnCart(
    input: {
      cart_id: $cartId
      billing_address: {
        address: {
          firstname: $firstname
          lastname: $lastname
          company: $company
          street: $street
          city: $city
          region: $region
          region_id: $regionId,
          postcode: $postCode
          country_code: $country_code
          telephone: $telephone
          save_in_address_book: $save_in_address_book
        }
        use_for_shipping: true
        same_as_shipping: false
      }
    }
  ) {
    cart {
      billing_address {
        firstname
        lastname
        company
        street
        city
        region{
          code
          label
        }
        postcode
        telephone
        country{
          code
          label
        }
      }
    }
  }
}`;

export const ADD_SHIPPING_ADDR = gql`
mutation addShippingAddr($firstname: String!, $lastname: String!, $company: String!, $street: [String]!, $city: String!, $region: String!,$regionId: Int!,
  $postCode: String!, $country_code: String!, $telephone: String!, $save_in_address_book: Boolean!, $cartId: String!
){
  setShippingAddressesOnCart(
    input: {
      cart_id: $cartId
      shipping_addresses: [
        {
            address: {
                firstname: $firstname
                lastname: $lastname
                company: $company
                street: $street
                city: $city
                region: $region
                region_id: $regionId,
                postcode: $postCode
                country_code: $country_code
                telephone: $telephone
                save_in_address_book: $save_in_address_book
            }
        }
      ]
    }
  ) {
    cart {
      shipping_addresses {
        firstname
        lastname
        company
        street
        city
        region {
          code
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
        pickup_location_code
        available_shipping_methods{
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
    }
  }
}`;

export const PLACE_ORDER_MUTATION = gql`
  mutation postOrderPlace($cartId: String!) {
    placeOrder(
      input: {
        cart_id: $cartId 
      }
    ) {
      order {
        order_number
      }
    }
  }
`;
export const SET_ORDER_Add_PAYMENT_METHOD_MUTATION = gql`
  mutation SetAddOrderPaymentMethod($cartId: String!, $paymentCode: String!) {
    setPaymentMethodOnCart(input: {
      cart_id: $cartId,
      payment_method: {
        code: $paymentCode
      }
    }) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`;
export const SET_SHIPPING_METHOD_MUTATION = gql`
  mutation SetShippingMethod($cartId: String!, $shippingMethods: [ShippingMethodInput]!) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cartId,
        shipping_methods: $shippingMethods
      }
    ) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const SET_BILLING_ADDRESS_CUST = gql`
mutation setBillingAddressCust($cart_id: String!, $customer_address_id: Int!) {
  setBillingAddressOnCart(
    input: {
      cart_id: $cart_id
      billing_address: {
        customer_address_id: $customer_address_id
        use_for_shipping: true
        same_as_shipping: false
      }
    }
  ) {
    cart {
      billing_address {
        firstname
        lastname
        company
        street
        city
        region{
          code
          label
        }
        postcode
        telephone
        country{
          code
          label
        }
      }
    }
  }
}
`;
export const SET_SHIPPING_ADDR_CUST = gql`
mutation setShippingAddrCust($cart_id: String!, $customer_address_id: Int!) {
  setShippingAddressesOnCart(
    input: {
      cart_id: $cart_id
      shipping_addresses: [
        {
            customer_address_id: $customer_address_id
        }
      ]
    }
  ) {
    cart {
      shipping_addresses {
        firstname
        lastname
        company
        street
        city
        region {
          code
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
        pickup_location_code
        available_shipping_methods{
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
    }
  }
}
`;
