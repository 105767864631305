import { gql } from "@apollo/client";

export const GET_FOOTERDATA = gql`
  query getfooterdata {
    getData(filter: {storeId: {eq: "1"}, apiType: {eq: "footer"}}) {
      name
      link
      section_name
      section
      description
      image
  }
  }
`;
export const GET_ABSOLUTEFOOTER = gql`
  query getabsolutefooter {
    getData(filter: {storeId: {eq: "1"}, apiType: {eq: "absoluteFooter"}}) {
      name
      link
      section_name
      section
      description
      image
  }
  }
`;
export const GET_FOOTERSTRIP = gql`
  query getfooterstrip {
    getData(filter: {storeId: {eq: "1"}, apiType: {eq: "footerStrip"}}) {
        name
        link
        section_name
        section
        description
    }
  }
`;
export const GET_APPICONS = gql`
  query getappicon {
    getData(filter: {storeId: {eq: "1"}, apiType: {eq: "apps"}}) {
        name
        link
        section_name
        section
        description
    }
  }
`;