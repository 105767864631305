import React, { useContext, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import * as mutations from '../../../operations/mutations';
import { useMutation } from '@apollo/client';
import { CountryContext } from "../MainComponent";



const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 128,
    },
}));
const pages = ['HOME', 'REFERRAL FEE', 'FAQ', 'ADVERTISE', 'SELLER TRAINING', 'JEBELZ.COM'];

function LoginHeader() {
    const { store } = useContext(CountryContext);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    let [generateCustomerToken, { data : generateCustData, loading : generateCustLoader, error:generateCustError }] = useMutation(mutations.loginMutation.LOGIN_MUTATION, {
        context: {
            headers: {
                Connection: "keep-alive",
                store: store,
            },
        },
    });

    const handleGenerateToken = async (event: any) => {

        event.preventDefault();
        const data = formData;
        try {
            const response = await generateCustomerToken({
                variables: data
            });
           generateCustData = response.data
        } catch (err) {
            if(err)
            {
                // generateCustError = err;
            }

        }
    }; 

    return (

        <Box sx={{ flexGrow: 1}}>
            <AppBar position="static" sx={{ backgroundColor: '#232f3e', color: '#fff' }}>
                <Container maxWidth={false}>
                    <StyledToolbar disableGutters>
                        <div className='toolbar-main'>
                            {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton> */}
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component={Link}
                                    to="/"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Seller Hub
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Button
                                            key={page}
                                            // onClick={handleCloseNavMenu}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Box>

                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button href="#text-buttons">Login</Button>
                                    <Button href="#text-buttons">Forgot Password</Button>
                                </Box>
                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={handleGenerateToken}
                                    autoComplete="off"
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                                        gap: 2
                                    }}
                                >

                                    <FormControl variant="standard">
                                        <BootstrapInput
                                            placeholder="Email"
                                            required
                                            fullWidth
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            autoComplete="off"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <BootstrapInput
                                            placeholder="Passsword"
                                            required
                                            fullWidth
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            autoComplete="off"
                                            onChange={handleChange} />
                                    </FormControl>
                                    <Button onClick={handleGenerateToken} variant="contained" disableElevation sx={{ textTransform: 'unset', color: '#fff' }}>
                                        Log in
                                    </Button>
                                </Box>
                                <Box>
                                    {/* <span>{generateCustError}</span> */}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Link to="/registration">
                                        <Button size="small" variant="contained" disableElevation sx={{ textTransform: 'unset', color: '#fff' }}>
                                            Register
                                        </Button>
                                    </Link>
                                </Box>

                            </Box>
                        </div>
                    </StyledToolbar>
                </Container>
            </AppBar>
        </Box>

    );

}

export default LoginHeader;