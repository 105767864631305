import { gql } from "@apollo/client";
export const GET_LOGO = gql`
  query getLogo {
    storeConfig {
      header_logo_src
      secure_base_media_url
    }
  }
`;
export const GET_STORES = gql`
  query getstores {
    getStores(filter: { storeId: { eq: "1" } }) {
      country
      flag
      websiteId
      store_code
      base_url
      countryName
      countryCode
    }
  }
`;
export const GET_MENUS = gql`
  query getMenus($parentId: String!) {
    categories(
      filters: { parent_id: { in: [$parentId] }, include_in_menu: { eq: "1" } }
    ) {
      total_count
      items {
        id
        include_in_menu
        level
        name
        path
        url_path
        url_key
        children_count
        children {
          id
          include_in_menu
          level
          name
          path
          url_path
          url_key
          children_count
          children {
            id
            include_in_menu
            level
            name
            path
            url_path
            url_key
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;
export const SEARCH_QUERY = gql`
  query getSearch($searchText: String!) {
    searchTerms(search: $searchText) {
      title
      num_results
      type
    }
    products(search: $searchText, pageSize: 20) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      total_count
      items {
        __typename
        name
        sku
        url_key
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        image {
          small_url
          url
          label
        }
        small_image {
          url
          label
        }
      }
      page_info {
        page_size
        current_page
      }
    }
  }
`;

// products(search: $searchText , pageSize: 20) {
//   total_count
//   items {
//     name
//     sku
//     price_range {
//       minimum_price {
//         regular_price {
//           value
//           currency
//         }
//       }
//     }
//     image {
//       url
//       label
//     }
//     small_image{
//         url
//         label
//     }
//   }
//   page_info {
//     page_size
//     current_page
//   }
// }
