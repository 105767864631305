import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { Container } from "@material-ui/core";
import CardHeader from '@mui/material/CardHeader';
import { FormControl, InputLabel, Input } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';


declare const window: any;
export default function TelsTest() {

    const telrIframeRef = React.createRef();
    const id = Math.floor((Math.random() * 9999999999) + 1)

    const [payload, setPayload] = useState({
        "telr_token": '',
        "store_id": '20906',
        "auth_key": 'jFBzR-ZJzq~524k5',
        "amount": '100',
        "currency": 'AED',
        "bill_fname": 'John',
        "bill_sname": 'Doe',
        "bill_addr1": 'Test Address Line 1',
        "bill_addr2": 'Test Address Line 2',
        "bill_city": 'Dubai',
        "bill_region": 'Dubai',
        "bill_zip": '11111',
        "bill_country": 'SA',
        "bill_email": 'customer_email@test.com',
        "bill_tel": '565656565'

    });

    const [successUrl, setSuccessUrl] = useState("")

    var onTokenReceive = function (telrToken: any) {
        /* Note: Include the received card token from iframe in payment form & send as ivp_ctoken parameter */
        setPayload({ ...payload, telr_token: telrToken })
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://secure.telr.com/jssdk/v2/telr_sdk.js';
        script.async = true;

        script.onload = () => {
            // Initialize Telr SDK here
            const telrSdk = window.telrSdk;
            var telr_params = {
                store_id: 12345,
                currency: 'AED',
                test_mode: 1,
                callback: onTokenReceive
            };
            telrSdk.init(telr_params);
        };

        document.head.appendChild(script);
    }, []);

    const onSubmitCall = () => {
        // Create a new FormData instance
        const formData = new FormData();
        // Append your data to the FormData object
        formData.append('telr_token', payload?.telr_token);
        formData.append('store_id', payload?.store_id);
        formData.append('auth_key', payload?.auth_key);
        formData.append('amount', payload?.amount);
        formData.append('currency', payload?.currency);
        formData.append('bill_fname', payload?.bill_fname);
        formData.append('bill_sname', payload?.bill_sname);
        formData.append('bill_addr1', payload?.bill_addr1);
        formData.append('bill_addr2', payload?.bill_addr2);
        formData.append('bill_city', payload?.bill_city);
        formData.append('bill_region', payload?.bill_region);
        formData.append('bill_zip', payload?.bill_zip);
        formData.append('bill_country', payload?.bill_country);
        formData.append('bill_email', payload?.bill_email);
        formData.append('bill_tel', payload?.bill_tel);


        // Create a config object for headers
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post("https://jebelzm2.ezmartech.com/telr/process.php", formData, config).then((res: any) => {
            setSuccessUrl(res?.data?.redirect_link)
        })
    }

    const handleChange = (event: any) => {
        setPayload({...payload, [event.target.name]: event.target.value})
    }

    return (
        <>
            <div className='payment-section'>
                <Container component="main" maxWidth="md">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >

                        <Grid container spacing={8}
                            justifyContent="center">
                            <Grid item xs={10}>
                                <Card sx={{ maxWidth: '100%', boxShadow: 'none', border: '1px solid #E0DEDE', mb: 3, p: 2 }}>

                                    <CardHeader
                                        sx={{
                                            backgroundColor: '#eee',
                                            padding: '12px',
                                            '& .MuiTypography-root': {
                                                fontSize: '24px',
                                                fontWeight: '700',
                                                color: '#000'
                                            },
                                        }}
                                        title="Add Payment" />
                                    <CardContent>
                                        <Box
                                            component="form"
                                            noValidate
                                            // onSubmit={handleCreateCustomer}
                                            sx={{ mt: 3 }}
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={2}>
                                                <Grid xs={12}>
                                                    <TextField type="hidden" name="telr_token" id="telr_token" value={payload?.telr_token} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="store_id" sx={{ mb: 1 }}>Store Id</InputLabel>
                                                    <TextField
                                                        name="store_id"    
                                                        type="text"
                                                        fullWidth
                                                        id="store_id"
                                                        placeholder="Store Id"
                                                        value={payload?.store_id}
                                                        autoFocus
                                                    onChange={handleChange}

                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    <InputLabel htmlFor="amount" sx={{ mb: 1 }}>Amount</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="amount"
                                                        id="amount"
                                                        placeholder="Amount"
                                                        value={payload?.amount}
                                                    onChange={handleChange}

                                                    />
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="amount" sx={{ mb: 1 }}>Amount</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="amount"
                                                        id="amount"
                                                        placeholder="Amount"
                                                        value={payload?.amount}
                                                    onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom component="h3" variant="h6" sx={{ fontWeight: 700, color: '#000' }}>
                                                        Billing Details
                                                    </Typography>
                                                    <Divider/>
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_fname" sx={{ mb: 1 }}>First Name</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_fname"
                                                        id="bill_fname"
                                                        placeholder="First Name"
                                                        value={payload?.bill_fname}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_sname" sx={{ mb: 1 }}>Last Name</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_sname"
                                                        id="bill_sname"
                                                        placeholder="Last Name"
                                                        value={payload?.bill_sname}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_addr1" sx={{ mb: 1 }}>Address Line 1</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_addr1"
                                                        id="bill_addr1"
                                                        placeholder="Address Line 1"
                                                        value={payload?.bill_addr1}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_addr2" sx={{ mb: 1 }}>Address Line 2</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_addr2"
                                                        id="bill_addr2"
                                                        placeholder="Address Line 2"
                                                        value={payload?.bill_addr2}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_city" sx={{ mb: 1 }}>City</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_city"
                                                        id="bill_city"
                                                        placeholder="City"
                                                        value={payload?.bill_city}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_region" sx={{ mb: 1 }}>Region/State</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_region"
                                                        id="bill_region"
                                                        placeholder="Region/State"
                                                        value={payload?.bill_region}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_zip" sx={{ mb: 1 }}>Zip Code</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_zip"
                                                        id="bill_zip"
                                                        placeholder="Zip Code"
                                                        value={payload?.bill_zip}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_country" sx={{ mb: 1 }}>Country</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_country"
                                                        id="bill_country"
                                                        placeholder="Country"
                                                        value={payload?.bill_country}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_email" sx={{ mb: 1 }}>Email Id</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_email"
                                                        id="bill_email"
                                                        placeholder="Email Id"
                                                        value={payload?.bill_email}
                                                        onChange={handleChange}

                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="bill_tel" sx={{ mb: 1 }}>Telephone</InputLabel>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        name="bill_tel" id="bill_tel"
                                                        placeholder="Telephone"
                                                        value={payload?.bill_tel}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>

                                                {successUrl && successUrl !== "" ?
                                                    <Grid item xs={12}>
                                                        <iframe src={successUrl} title="success" style={{width:' 100%',height: '404px'}}/>
                                                    </Grid>
                                                    : <Grid item xs={12}>
                                                        <div id="telr_frame"></div>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>


                                                    <Button
                                                    variant="contained" disableElevation
                                                        type="submit"
                                                        id="submit_form"
                                                        className="pull-right btn btn-primary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            onSubmitCall()
                                                        }}
                                                        sx={{color:'#fff'}}
                                                    >Proceed to Pay</Button>


                                                </Grid>


                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>

        </>
    )
}