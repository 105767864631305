import './App.css';
import { ThemeProvider,CssBaseline } from '@mui/material';
import theme from './theme';
import MainComponent from './container/buyer/MainComponent';

// const theme = createTheme({
// ,});
function App() {
  return (
    <ThemeProvider theme={theme}>
       <CssBaseline />
      <MainComponent/>
   </ThemeProvider>
  );
}

export default App;
