import React, { useState, useContext, useEffect, useMemo } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from '@mui/material/Link';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import dTop from "../../../assets/image/dtop.png";
import Chip from "@mui/material/Chip";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import SelectDrop from "react-select";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import imageDemo4 from "../../../assets/image/t1.png";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as queries from "../../../operations/queries";
import { useQuery, gql } from "@apollo/client";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useParams } from "react-router";
import { CountryContext, mainContext } from "../MainComponent";
// import { mainContext } from "../layout";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Loader from "../Layout/loader";
import ReactImageMagnify from "react-image-magnify";
import ReactStars from "react-rating-star-with-type";
import Checkbox from "@mui/material/Checkbox";
import StarIcon from "@mui/icons-material/Star";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const qtyOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];
function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ratingProgressData = [
  { id: 5, color: "#38ae04" },
  { id: 4, color: "#82ae04" },
  { id: 3, color: "#f3ac30" },
  { id: 2, color: "#f36c32" },
  { id: 1, color: "#f36c32" },
];

const addcartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M14.5683 18.6108C15.047 18.6108 15.435 18.2228 15.435 17.7441C15.435 17.2654 15.047 16.8773 14.5683 16.8773C14.0896 16.8773 13.7015 17.2654 13.7015 17.7441C13.7015 18.2228 14.0896 18.6108 14.5683 18.6108Z"
      stroke="white"
      strokeWidth="0.538319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.20159 18.6108C6.68028 18.6108 7.06834 18.2228 7.06834 17.7441C7.06834 17.2654 6.68028 16.8773 6.20159 16.8773C5.7229 16.8773 5.33484 17.2654 5.33484 17.7441C5.33484 18.2228 5.7229 18.6108 6.20159 18.6108Z"
      stroke="white"
      strokeWidth="0.538319"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.4209 5.19434H3.81125L5.60402 15.3533H15.1654"
      stroke="white"
      strokeWidth="1.00935"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.604 12.9629H14.9204C14.9895 12.9629 15.0565 12.939 15.11 12.8952C15.1634 12.8514 15.2 12.7905 15.2136 12.7227L16.2893 7.34441C16.2979 7.30104 16.2969 7.25629 16.2862 7.21338"
      stroke="white"
      strokeWidth="1.00935"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.631 1.38428L10.0118 9.00354L6.20215 5.19391"
      stroke="white"
      strokeWidth="1.21122"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const linkStyle = {
  textDecoration: "none",
};
const rmBtn = (
  <Link to="#" className="rmBtn" style={linkStyle}>
    Read More
  </Link>
);
const img4 = (
  <div className="tab-box">
    <img src={imageDemo4} alt="" />
  </div>
);
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.", event);
}
function DetailsPage(props: any, { customerId }: any) {
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { getCartId } = props;
  const { country, setCountry, store } = useContext(CountryContext);
  const {
    cartData,
    setCartData,
    setCartCount,
    quest_cart_id,
    set_quest_cart_id,
  } = useContext(mainContext);
  const { t } = useTranslation();
  const [rvalue, setRtabValue] = React.useState(0);
  const [lvalue, setLtabValue] = useState(0);
  let galleryImage: any = [];
  let smallImage: any = [];
  let variantsDetails: any = "";
  let colorVariation: any = "";
  let cartId: any = quest_cart_id || "";
  const params = useParams();
  const urlKey = params.urlKey?.split(".html");
  const [proQty, setProQty] = React.useState("1");
  const [tempCartId, setTempCartId] = React.useState(cartId);
  const [userErrorsCart, setUserErrorsCart] = useState("");

  const {
    loading: detailsloader,
    data: detailsdata,
    refetch: refetchProData,
  } = useQuery(queries.detailsQueries.GET_CONFDETAILS, {
    variables: { url_key: urlKey && urlKey[0] },
    context: {
      headers: {
        store: store,
        Connection: "keep-alive",
      },
    },
  });

  useEffect(() => {
    refetchProData();
  }, [store, refetchProData]);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const breadcrumbs = [
    <Link key="1" color="inherit" to="/">
      {t("inner_pages.detail_page.home")}
    </Link>,
    <Link
      style={linkStyle}
      key="2"
      color="inherit"
      to="#"
      onClick={handleClick}
    >
      {urlKey && urlKey[0]}
    </Link>,
    // <Typography key="3" color="text.primary">
    //     Coffee Machines
    // </Typography>,
  ];

  const {
    loading: cartDetailsLoading,
    error: cartDetailsErr,
    data: cartDetailsRes,
    refetch: getCartRefetch,
  } = useQuery(queries.cartQueries.GET_CART_DETAILS, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const detailsData = detailsdata?.products?.items[0];

  useMemo(() => {
    if (
      cartDetailsRes &&
      cartDetailsRes?.cart &&
      cartDetailsRes?.cart?.items?.length
    ) {
      setCartCount(cartDetailsRes?.cart?.items?.length);
      set_quest_cart_id(tempCartId);
      setCartData(cartDetailsRes?.cart?.items);
    }
  }, [cartDetailsRes]);

  if (detailsData?.variants && detailsData?.variants.length > 0) {
    variantsDetails = detailsData?.variants[lvalue].product;
    colorVariation = detailsData?.variants[lvalue].attributes[0].label;
  }
  let childSkuName =
    variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.sku
      : detailsData?.sku;

  if (detailsData?.variants && detailsData?.variants.length > 0) {
    detailsData?.variants.map((items: any, index: any) => {
      smallImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.small_image?.url}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      );
    });
  } else {
    smallImage = (
      <div className="tab-box">
        <img
          className="img-cover"
          src={detailsData?.small_image?.url}
          alt="small image"
        />
      </div>
    );
  }
  if (detailsData?.variants && detailsData?.variants.length > 0) {
    detailsData?.variants.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.media_gallery[index]?.thumbnail_url}
            alt=""
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      );
    });
  } else {
    detailsData?.media_gallery.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img src={items?.thumbnail_url} alt="" />
        </div>
      );
    });
  }
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const description = detailsData?.short_description?.html;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setRtabValue(newValue);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setLtabValue(newValue);
  };

  const handledQtyChange = (event: SelectChangeEvent) => {
    setProQty(event.target.value as string);
  };

  const [addProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_SIMPLE_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const [guestCart] = useMutation(mutations.addToCartMutation.GUEST_CART_DATA, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });
  const [loggedUserCart] = useMutation(
    mutations.addToCartMutation.LOGGUSER_CART_DATA,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const addSimpleCart = () => {
    return new Promise((resolve) => {
      addProductsToCart({
        variables: {
          // cartId: 'AdGvg2DjN1YedY5ZgoyIQvBJ8xHs0Ptq',
          cartId: cartId,
          quantity: proQty,
          sku: detailsData?.sku,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      })
        .then((response) => {
          setUserErrorsCart(
            response?.data?.addProductsToCart?.user_errors[0].code
          );
          getCartId(cartId);
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  };

  const [addConfigurableProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_CONFIG_PRODUCTS_TO_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleConfigProdCart = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await addConfigurableProductsToCart({
          variables: {
            input: {
              cart_id: cartId,
              cart_items: [
                {
                  parent_sku: detailsData?.sku,
                  data: {
                    quantity: proQty,
                    sku: childSkuName,
                  },
                },
              ],
            },
          },
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store,
            },
          },
        });
        resolve(true);
        getCartId(cartId);
      } catch (error) {
        resolve(false);
        console.error(error);
      }
    });
  };

  const { loading: custQueryLoader, data: custQueryDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    set_quest_cart_id(cartId);
    localStorage.setItem("quest_cart_id", cartId);
  }, [custQueryDataRes]);

  const handleAddToCart = async () => {
    if (loggedInToken && loggedInToken !== "") {
      if (custQueryDataRes && custQueryDataRes?.customerCart?.id !== "") {
        cartId = custQueryDataRes?.customerCart?.id;
        localStorage.setItem("quest_cart_id", cartId);
        set_quest_cart_id(cartId);
      } else {
        loggedUserCart({})
          .then(async (response) => {
            if (response.data && response.data.createEmptyCart) {
              cartId = response.data.createEmptyCart;
              localStorage.setItem("quest_cart_id", cartId);
              set_quest_cart_id(cartId);
            }
            // setCartData(detailsData)
            // if (detailsData?.variants && detailsData?.variants.length > 0) {

            //     await handleConfigProdCart();
            // }else{
            //     await addSimpleCart();
            // }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart();
        } else {
          await addSimpleCart();
        }
        getCartRefetch();
      }
    } else {
      if (cartId && cartId !== "undefined") {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart();
        } else {
          await addSimpleCart();
        }
        getCartRefetch();
      } else {
        guestCart({})
          .then(async (response) => {
            if (response.data && response.data.createEmptyCart) {
              cartId = response.data.createEmptyCart;
              localStorage.setItem("quest_cart_id", cartId);
              set_quest_cart_id(cartId);
              if (detailsData?.variants && detailsData?.variants.length > 0) {
                await handleConfigProdCart();
              } else {
                await addSimpleCart();
              }

              setTempCartId(cartId);
            } else {
              console.error("Invalid response data structure");
            }
            getCartRefetch();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const [age, setAge] = React.useState("");

  const handleChangee = (event: any) => {
    setAge(event.target.value);
  };

  const [alignment, setAlignment] = React.useState("web");

  const handleChangeee = (event: any, newAlignment: any) => {
    setAlignment(newAlignment);
  };
  return (
    <>
      {detailsloader ? (
        <Loader />
      ) : (
        <div className="details-wrapper" style={{ backgroundColor: "#fff" }}>
          {/* <Button variant="text" onClick={handleConfigProdCart}>Text</Button> */}
          {detailsData ? (
            <Container maxWidth={false}>
              <Box sx={{ pt: 2 }}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div className="breadcrumb-section">
                      <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                        className="breadcrumb-data"
                      >
                        {breadcrumbs}
                      </Breadcrumbs>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 2 }}>
                <Grid container>
                  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Item sx={{ boxShadow: 0, height: "100%" }}>
                      <div className="right-img-tab cust-tabs-list">
                        <Box
                          className="cust-tabs-view-img directionrtl"
                          sx={{
                            flexGrow: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                            height: 550,
                          }}
                        >
                          {detailsData?.variants &&
                          detailsData?.variants?.length > 0 ? (
                            <>
                              <Tabs
                                sx={{ width: "140px", overflow: "auto" }}
                                className="right-tabs"
                                orientation="vertical"
                                variant="scrollable"
                                value={rvalue}
                                // onChange={handleChange}
                                aria-label="Media gallery tabs "
                                // sx={{ borderRight: 1, borderColor: 'divider' }}
                              >
                                {detailsData?.variants[
                                  lvalue
                                ]?.product?.media_gallery?.map(
                                  (items: any, index: any) => {
                                    return (
                                      <Tab
                                        {...a11yProps(index)}
                                        key={index}
                                        label={
                                          <div className="tab-box">
                                            <img
                                              src={items?.thumbnail_url}
                                              alt=""
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                        }
                                        onMouseEnter={(e) =>
                                          handleChange(e, index)
                                        }
                                      />
                                    );
                                  }
                                )}
                              </Tabs>
                              <TabPanel value={rvalue} index={rvalue}>
                                <div>
                                  <ReactImageMagnify
                                    {...{
                                      className: "-magnifyimg",
                                      smallImage: {
                                        alt: "Jebelz product image",
                                        isFluidWidth: true,
                                        // width: 300,
                                        src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`,
                                        srcSet: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`,
                                        sizes:
                                          "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                      },
                                      largeImage: {
                                        alt: "",
                                        src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`,
                                        width: 1000,
                                        height: 1500,
                                      },
                                      enlargedImageContainerStyle: {
                                        zIndex: "1500",
                                      },
                                      enlargedImageContainerDimensions: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      isHintEnabled: true,
                                    }}
                                  />
                                </div>
                              </TabPanel>
                            </>
                          ) : (
                            <>
                              <Tabs
                                sx={{ width: "140px" }}
                                className="right-tabs"
                                orientation="vertical"
                                variant="scrollable"
                                value={rvalue}
                                onChange={handleChange}
                                aria-label="Media gallery tabs "
                                // sx={{ borderRight: 1, borderColor: 'divider' }}
                              >
                                {[
                                  ...Array(detailsData?.media_gallery?.length),
                                ].map((items, index) => (
                                  <Tab
                                    {...a11yProps(
                                      detailsData?.media_gallery[index]
                                    )}
                                    key={index}
                                    label={galleryImage[index]}
                                    onMouseEnter={(e) => handleChange(e, index)}
                                  />
                                ))}
                              </Tabs>
                              <TabPanel value={rvalue} index={rvalue}>
                                {/* <img
                                  className="img-cover"
                                  src={detailsData?.media_gallery[rvalue]?.url}
                                  alt=""
                                /> */}
                                <ReactImageMagnify
                                  {...{
                                    className: "-magnifyimg",
                                    smallImage: {
                                      alt: "Jebelz product image",
                                      isFluidWidth: true,
                                      // width: 300,
                                      src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                      srcSet: `${detailsData?.media_gallery[rvalue]?.url}`,
                                      sizes:
                                        "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                    },
                                    largeImage: {
                                      alt: "",
                                      src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                      width: 1000,
                                      height: 1500,
                                    },
                                    enlargedImageContainerStyle: {
                                      zIndex: "1500",
                                    },
                                    enlargedImageContainerDimensions: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    isHintEnabled: true,
                                  }}
                                />
                              </TabPanel>
                            </>
                          )}
                          {/* {[...Array(detailsData?.media_gallery?.length)].map((items, index) => ( */}

                          {/* ))} */}
                        </Box>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                    <Item
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                        boxShadow: 0,
                        paddingRight: 0,
                      }}
                    >
                      <Box
                        sx={{ textAlign: "start" }}
                        className="details-center-content"
                      >
                        <div className="top-cart-box">
                          {/* <Button variant="contained" size="small" startIcon={addcartIcon} style={{color:'#fff'}}>
                                                In add to cart
                                            </Button> */}
                        </div>
                        {detailsData?.brand_logo_img && (
                          <div className="dtop-img">
                            <Link
                              to={`/${store}/category/${detailsData?.brand_logo_link}`}
                            >
                              <img
                                src={detailsData?.brand_logo_img}
                                alt={detailsData?.brand_logo_img}
                              />
                            </Link>
                          </div>
                        )}
                        {/* {detailsData?.variants.length > 0 ? */}
                        <Stack spacing={2} direction="column">
                          <Typography variant="h3" component="h3">
                            {variantsDetails && variantsDetails !== undefined
                              ? variantsDetails?.name
                              : detailsData?.name}
                            {/* {variantsDetails.name : detailsData.name} */}
                          </Typography>
                          <a href="#review_rating">
                            <div className="rating-box">
                              <Chip
                                label={detailsData?.rating_summary}
                                icon={<StarOutlinedIcon />}
                                color="success"
                              />
                              <span className="total-rating">
                                {detailsData?.rating_summary}{" "}
                                {t("inner_pages.detail_page.ratings")}
                              </span>
                            </div>
                          </a>
                          <div className="amount-box">
                            <div className="amount-was">
                              <label>Was :</label>

                              <label>
                                {variantsDetails &&
                                variantsDetails !== undefined ? (
                                  <>
                                    {
                                      variantsDetails?.price_range
                                        ?.minimum_price?.regular_price?.currency
                                    }{" "}
                                    {
                                      variantsDetails?.price_range
                                        ?.minimum_price?.regular_price?.value
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      detailsData?.price_range?.minimum_price
                                        ?.regular_price?.currency
                                    }{" "}
                                    {
                                      detailsData?.price_range?.minimum_price
                                        ?.regular_price?.value
                                    }
                                  </>
                                )}
                              </label>
                            </div>
                            <div className="amount-now">
                              <label>Now :</label>
                              <Typography variant="h4" component="h4">
                                {variantsDetails &&
                                variantsDetails !== undefined ? (
                                  <>
                                    {
                                      variantsDetails?.price_range
                                        ?.minimum_price?.regular_price?.currency
                                    }{" "}
                                    {
                                      variantsDetails?.price_range
                                        ?.minimum_price?.regular_price?.value
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      detailsData?.price_range?.minimum_price
                                        ?.final_price?.currency
                                    }{" "}
                                    {
                                      detailsData?.price_range?.minimum_price
                                        ?.final_price?.value
                                    }
                                  </>
                                )}
                                &nbsp;
                                <span
                                  style={{
                                    fontSize: "14px",
                                    color: "#3D3D3D",
                                    fontWeight: "400",
                                  }}
                                >
                                  {country === "in"
                                    ? t("main.inclusive_of_gst")
                                    : t("main.inclusive_of_vat")}
                                </span>
                              </Typography>
                            </div>
                            <div
                              className="stock-msg"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                gap: "4px",
                              }}
                            >
                              <label>{detailsData?.stock_status === "IN_STOCK" ? "In Stock" : detailsData?.stock_status}</label>
                              <div className="delivr-status-msg">
                                <label>
                                  {t(
                                    "inner_pages.detail_page.delivery_estimation"
                                  )}{" "}
                                  :{" "}
                                </label>
                                <span>{detailsData?.delivery_estimation}</span>
                              </div>
                            </div>
                          </div>
                          <>
                            {userErrorsCart && (
                              <div
                                key={userErrorsCart}
                                className="insufficient-error"
                              >
                                <label>{userErrorsCart}</label>
                              </div>
                            )}
                          </>
                          {/* <div className="delivr-status-msg">
                                                <label>Delivery Estimation : </label>
                                                <span>{detailsData?.delivery_estimation}</span>
                                            </div> */}
                          <div className="qty-box">
                            <Box
                              sx={{
                                minWidth: 100,
                                display: "flex",
                                gap: "12px",
                                alignItems: "end",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <label>
                                  {t("inner_pages.detail_page.qty")}
                                </label>

                                <FormControl
                                  fullWidth
                                  sx={{
                                    border: "1px solid #C6C3C3",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <Select
                                    IconComponent={CustomSelectIcon}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="qty-select"
                                    value={proQty}
                                    label="Quantity"
                                    onChange={handledQtyChange}
                                  >
                                    {qtyOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                              <Button
                                className="add-to-cart-btn"
                                onClick={handleAddToCart}
                                variant="contained"
                                startIcon={<LocalMallOutlinedIcon />}
                                size="large"
                                disabled={
                                  detailsData?.stock_status === "OUT_OF_STOCK"
                                    ? true
                                    : false
                                }
                                // disabled={cartButtonDisabled}
                                // className={`cart-button ${cartButtonDisabled ? "disabled" : ""}`}
                              >
                                {t("inner_pages.detail_page.add_to_cart")}
                              </Button>
                              {/* <Addcart isOpenPopup={openPopup} isAnchor={anchor} handleClosePopup={handleClosePopup}/> */}

                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{
                                  borderRadius: "5px",
                                  border: "1px solid #E7E5E5",
                                  background: "#F8F4F4",
                                  p: 1,
                                }}
                              >
                                <FavoriteBorderOutlinedIcon fontSize="medium" />
                              </IconButton>
                            </Box>
                          </div>
                          <div className="add-cart-grp"></div>
                          {detailsData?.variants &&
                          detailsData?.variants.length > 0 ? (
                            <div className="color-name-block">
                              <label>
                                {t("inner_pages.detail_page.color_name")} :{" "}
                              </label>
                              <span>
                                {colorVariation && colorVariation !== undefined
                                  ? colorVariation
                                  : ""}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {detailsData?.variants &&
                          detailsData?.variants.length > 0 ? (
                            <div className="custom-img-tab cust-tabs-list">
                              <Box>
                                <Tabs
                                  value={lvalue}
                                  onChange={handleChangeTab}
                                  aria-label="image tabs"
                                >
                                  {Array.from(
                                    Array(detailsData.variants.length)
                                  ).map((_, index) => (
                                    <Tab
                                      label={smallImage[index]}
                                      {...a11yProps(index)}
                                      key={index}
                                      sx={{ p: 1 }}
                                    />
                                  ))}
                                </Tabs>
                              </Box>
                            </div>
                          ) : (
                            ""
                          )}

                          <Typography
                            variant="body2"
                            component="ul"
                            className="prod-description"
                            sx={{ px: 0 }}
                          >
                            <div
                              className={`description-text ${
                                expanded ? "expanded" : ""
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                            />
                            {description && description.length > 4 * 90 ? (
                              <span
                                onClick={toggleExpanded}
                                className="show-more-link"
                              >
                                {expanded ? "Read less" : "Read more"}
                              </span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Stack>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                    <Item sx={{ boxShadow: 0 }}>
                      <div className="details-right-content">
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                          }}
                        >
                          {/* <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                >
                                  <path
                                    d="M25.1393 26.9738C23.8511 27.8152 22.3124 28.3044 20.657 28.3044C19.366 28.3044 18.1468 28.0069 17.0621 27.4775C15.7149 26.8201 14.5737 25.804 13.7634 24.5538C12.9317 23.2705 12.4487 21.7409 12.4487 20.096C12.4487 18.2417 13.0627 16.533 14.0988 15.159C15.5982 13.1706 17.9777 11.8877 20.657 11.8877C25.1904 11.8877 28.8654 15.5627 28.8654 20.096C28.8654 21.2592 28.6239 22.3641 28.189 23.3646C27.5458 24.8447 26.4782 26.0993 25.1393 26.9738Z"
                                    fill="#1082C2"
                                    stroke="#333848"
                                  />
                                  <path
                                    d="M16.4673 20.0294L19.4508 23.0622L25.0857 17.1299"
                                    stroke="white"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M6.08545 8.0957H20.6155C27.2429 8.0957 32.6156 13.4683 32.6156 20.0957C32.6156 26.723 27.2429 32.0958 20.6155 32.0958H16.7755"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M8.38427 5.1543L5.38428 8.09604L8.38427 11.5646"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M12.9543 32.0957H9.80713"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content"
                              primary={t("inner_pages.detail_page.any-without")}
                              secondary={rmBtn}
                            />
                          </ListItem> */}
                          {/* <Divider variant="middle" component="li" /> */}
                          {/* <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="29"
                                  height="41"
                                  viewBox="0 0 29 41"
                                  fill="none"
                                >
                                  <path
                                    d="M8.40186 30.7002V40.0004L14.6574 34.5927L20.8099 40.0004V26.0688"
                                    fill="#1082C2"
                                  />
                                  <path
                                    d="M8.40186 30.7002V40.0004L14.6574 34.5927L20.8099 40.0004V26.0688"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21.7151 25.509C19.6413 26.8637 17.1642 27.6511 14.4999 27.6511C12.4222 27.6511 10.4594 27.1724 8.71289 26.3201C6.54447 25.2617 4.70767 23.6265 3.40343 21.6141C2.06461 19.5482 1.28711 17.0858 1.28711 14.4384C1.28711 11.4537 2.27551 8.70284 3.9433 6.4912C6.35648 3.29111 10.1868 1.22559 14.4999 1.22559C21.7972 1.22559 27.7127 7.14115 27.7127 14.4384C27.7127 16.3103 27.324 18.0889 26.6237 19.6999C25.5882 22.0825 23.8698 24.1018 21.7151 25.509Z"
                                    fill="white"
                                    stroke="#404553"
                                  />
                                  <path
                                    d="M9.74512 14.5341L13.5754 18.4275L20.8097 10.8115"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content"
                              primary={t("inner_pages.detail_page.work")}
                              secondary={rmBtn}
                            />
                          </ListItem>
                          <Divider variant="middle" component="li" /> */}
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                >
                                  <path
                                    d="M25.1393 26.9738C23.8511 27.8152 22.3124 28.3044 20.657 28.3044C19.366 28.3044 18.1468 28.0069 17.0621 27.4775C15.7149 26.8201 14.5737 25.804 13.7634 24.5538C12.9317 23.2705 12.4487 21.7409 12.4487 20.096C12.4487 18.2417 13.0627 16.533 14.0988 15.159C15.5982 13.1706 17.9777 11.8877 20.657 11.8877C25.1904 11.8877 28.8654 15.5627 28.8654 20.096C28.8654 21.2592 28.6239 22.3641 28.189 23.3646C27.5458 24.8447 26.4782 26.0993 25.1393 26.9738Z"
                                    fill="#1082C2"
                                    stroke="#333848"
                                  />
                                  <path
                                    d="M16.4673 20.0294L19.4508 23.0622L25.0857 17.1299"
                                    stroke="white"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M6.08545 8.0957H20.6155C27.2429 8.0957 32.6156 13.4683 32.6156 20.0957C32.6156 26.723 27.2429 32.0958 20.6155 32.0958H16.7755"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M8.38427 5.1543L5.38428 8.09604L8.38427 11.5646"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M12.9543 32.0957H9.80713"
                                    stroke="#333848"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content-title"
                              primary={t("inner_pages.detail_page.free-return")}
                              secondary={t(
                                "inner_pages.detail_page.free-return-content"
                              )}
                            />
                          </ListItem>
                          <Divider variant="middle" component="li" />
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="20"
                                  viewBox="0 0 32 20"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M22.0227 16.0797H20.8853V4.39551H25.9081L30.7539 8.48644V16.0797H29.8938"
                                    fill="#1082C2"
                                  />
                                  <path
                                    d="M22.0227 16.0797H20.8853V4.39551H25.9081L30.7539 8.48644V16.0797H29.8938"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M23.0942 6.729H25.6058L28.0093 8.77758V10.2343H23.0942V6.729Z"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M20.5148 1.71582H4.82324V15.8383H20.5148V1.71582Z"
                                    fill="#1082C2"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.77439 16.0794H5.0127V12.3114M5.0127 5.16554V1.47412H20.8857V16.0794H16.0553"
                                    fill="#1082C2"
                                  />
                                  <path
                                    d="M7.77442 16.8187C8.18265 16.8187 8.51371 16.4877 8.51371 16.0794C8.51371 15.6712 8.18265 15.3401 7.77442 15.3401V16.8187ZM5.01273 16.0794H4.27344C4.27344 16.4877 4.60449 16.8187 5.01273 16.8187V16.0794ZM5.75202 12.3114C5.75202 11.903 5.42111 11.5721 5.01273 11.5721C4.60449 11.5721 4.27344 11.903 4.27344 12.3114H5.75202ZM4.27344 5.16558C4.27344 5.57381 4.60449 5.90487 5.01273 5.90487C5.42096 5.90487 5.75202 5.57381 5.75202 5.16558H4.27344ZM5.01273 1.47415V0.734863C4.60449 0.734863 4.27344 1.06577 4.27344 1.47415H5.01273ZM20.8857 1.47415H21.625C21.625 1.06577 21.294 0.734863 20.8857 0.734863V1.47415ZM20.8857 16.0794V16.8187C21.294 16.8187 21.625 16.4877 21.625 16.0794H20.8857ZM16.0554 15.3401C15.6471 15.3401 15.3161 15.6712 15.3161 16.0794C15.3161 16.4877 15.6471 16.8187 16.0554 16.8187V15.3401ZM7.77442 15.3401H5.01273V16.8187H7.77442V15.3401ZM5.75202 16.0794V12.3114H4.27344V16.0794H5.75202ZM5.75202 5.16558V1.47415H4.27344V5.16558H5.75202ZM5.01273 2.21344H20.8857V0.734863H5.01273V2.21344ZM20.1464 1.47415V16.0794H21.625V1.47415H20.1464ZM20.8857 15.3401H16.0554V16.8187H20.8857V15.3401Z"
                                    fill="#404553"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.644 18.9998C12.9428 18.9998 13.9955 17.9536 13.9955 16.6631C13.9955 15.3724 12.9428 14.3262 11.644 14.3262C10.3454 14.3262 9.29248 15.3724 9.29248 16.6631C9.29248 17.9536 10.3454 18.9998 11.644 18.9998Z"
                                    fill="#1082C2"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M25.8579 18.9998C27.1567 18.9998 28.2094 17.9536 28.2094 16.6631C28.2094 15.3724 27.1567 14.3262 25.8579 14.3262C24.5591 14.3262 23.5063 15.3724 23.5063 16.6631C23.5063 17.9536 24.5591 18.9998 25.8579 18.9998Z"
                                    fill="#1082C2"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.07275 7.02344H9.35446"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1.09961 9.55518H4.48912"
                                    stroke="#404553"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content-title"
                              primary={t(
                                "inner_pages.detail_page.trusted-shipping"
                              )}
                              secondary={t(
                                "inner_pages.detail_page.trusted-shipping-content"
                              )}
                            />
                          </ListItem>
                          <Divider variant="middle" component="li" />
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                >
                                  <path
                                    d="M23.2273 1.00049H5.89453V26.9997H23.2273V1.00049Z"
                                    fill="#1082C2"
                                    stroke="#404553"
                                  />
                                  <path
                                    d="M27.5605 26.9995H5.89453"
                                    stroke="#404553"
                                  />
                                  <path
                                    d="M9.50537 14.7224V10.3892"
                                    stroke="#404553"
                                  />
                                  <path
                                    d="M10.228 17.6562H0.898438V26.9998H10.228V17.6562Z"
                                    fill="#1082C2"
                                    stroke="#404553"
                                  />
                                  <path
                                    d="M14.631 20.4204H8.06104V27.0004H14.631V20.4204Z"
                                    fill="white"
                                    stroke="#404553"
                                  />
                                </svg>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content-title"
                              primary={t(
                                "inner_pages.detail_page.contactless-delivery"
                              )}
                              secondary={t(
                                "inner_pages.detail_page.your-delivery-left"
                              )}
                            />
                          </ListItem>
                        </List>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ pt: 5 }}>
                <Grid container>
                  <Grid
                    item
                    xl={7}
                    lg={7}
                    md={6}
                    sm={12}
                    xs={12}
                    className="dsc-block"
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h5"
                      sx={{ px: 1 }}
                    >
                      {t("inner_pages.detail_page.overview")}
                    </Typography>
                    <Divider />

                    <Box sx={{ mt: 2, px: 1 }}>
                      <div className="overview-box">
                        <Typography component="div">
                          <ul className="overview-content">
                            <li style={{ listStyle: "none" }}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: detailsData?.description?.html,
                                }}
                                style={{ whiteSpace: "initial" }}
                              />
                            </li>
                          </ul>
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    className="dsc-block"
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h5"
                      sx={{ px: 1 }}
                    >
                      {t("inner_pages.detail_page.specification")}
                    </Typography>
                    <Divider />
                    <Box sx={{ mt: 2, px: 1 }}>
                      <TableContainer>
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="customized table"
                          className="specification-tbl"
                        >
                          <TableBody>
                            {detailsData.attributes.map(
                              (row: any, index: any) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell component="th" scope="row">
                                    {row.label}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {row.value}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <div id="review_rating">
                <Box sx={{ pt: 5 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h5"
                    sx={{ px: 1, mb: 2, fontWeight: "bold" }}
                  >
                    {t("inner_pages.detail_page.pro-review-rating")}
                  </Typography>
                  <Divider />
                  <Grid container>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      className="dsc-block review-start"
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        sx={{ px: 1, mt: 2 }}
                      >
                        {t("inner_pages.detail_page.overallRating")}
                      </Typography>
                      {/* {detailsData?.rating_summary ? ( */}
                      <Box sx={{ mt: 2, px: 1 }}>
                        <div className="">
                          {/* <Typography sx={{ px: 1 }}>
                        {t("inner_pages.detail_page.overallRating")}
                      </Typography> */}
                          <Typography>
                            {/* {detailsData?.rating_summary > 0 && ( */}
                            <>
                              <Typography
                                variant="h5"
                                component="h5"
                                sx={{ px: 1, fontWeight: "bold" }}
                              >
                                {detailsData?.rating_summary}
                              </Typography>
                              <ReactStars
                                size={18}
                                isHalf={true}
                                activeColor="#38ae04"
                                value={detailsData?.rating_summary}
                                activeColors={[
                                  "#38ae04",
                                  "#38ae04",
                                  "#38ae04",
                                  "#38ae04",
                                  "#38ae04",
                                ]}
                              />
                            </>
                            {/* )} */}
                          </Typography>
                          {/* <p className="total-ratings-count">Based on 10688 ratings</p>
                        -------5 star start----------
                        {ratingProgressData.map((item: any) => (
                          <div className="rating-wrap">
                            <div>
                              <Checkbox  sx={{ p: 0 }} {...label} defaultChecked />
                            </div>
                            <div className="star-wrap">
                              {item.id} <StarIcon />
                            </div>
                            <div className="progress-wrap"> 
                              {" "}
                              <BorderLinearProgress
                                variant="determinate"
                                value={30}
                              />
                            </div>
                            <div>(4.5K)</div>
                          </div>
                        ))} */}
                          {/* -------5 star end---------- */}
                        </div>
                        <div>
                          <Typography variant="h6" sx={{ px: 1, mt: 4 }}>
                            <span>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="starCircle"
                              >
                                <path
                                  d="M8.13183 16.0276C12.2658 16.0276 15.6299 12.6635 15.6299 8.52955C15.6299 4.37051 12.2634 0.990479 8.13183 0.990479C4.00025 0.990479 0.633778 4.37051 0.633778 8.52955C0.633778 12.6635 3.99785 16.0276 8.13183 16.0276Z"
                                  fill="#FEEE00"
                                  stroke="#404554"
                                ></path>
                                <path
                                  d="M10.4769 8.77907L10.4769 8.77901L10.4716 8.78333C10.3051 8.91944 10.1706 9.17541 10.2573 9.44775L10.7494 11.3753C10.76 11.4326 10.7439 11.4735 10.7203 11.5016C10.6932 11.534 10.6527 11.5532 10.6108 11.5532C10.6041 11.5532 10.5914 11.552 10.575 11.5472C10.559 11.5425 10.5436 11.5355 10.5306 11.5273L10.5307 11.5271L10.5215 11.5217L8.72256 10.4592C8.72236 10.4591 8.72215 10.459 8.72194 10.4589C8.51204 10.3341 8.25031 10.334 8.04035 10.4587C8.04003 10.4588 8.03971 10.459 8.03939 10.4592L6.25449 11.5085L6.25439 11.5084L6.24482 11.5144C6.2318 11.5227 6.21638 11.5297 6.20035 11.5344C6.18403 11.5392 6.17129 11.5404 6.16455 11.5404C6.11889 11.5404 6.0705 11.518 6.04104 11.4842C6.02768 11.4688 6.02044 11.4537 6.0172 11.4401C6.01419 11.4274 6.01285 11.4089 6.02076 11.382L6.02096 11.3821L6.02384 11.3708L6.52085 9.42393L6.52092 9.42395L6.52247 9.41728C6.57641 9.18584 6.49803 8.92929 6.30378 8.77052L6.30381 8.77048L6.29925 8.76689L4.67512 7.48703C4.64585 7.46335 4.63437 7.44038 4.62948 7.42171C4.62402 7.40082 4.62446 7.37538 4.63379 7.34869C4.65219 7.29607 4.69882 7.25247 4.7735 7.24802L4.7735 7.24802L4.77584 7.24787L6.8848 7.10697L6.88482 7.10722L6.89742 7.10589C7.1304 7.0812 7.3314 6.93654 7.43399 6.74089L7.43975 6.72991L7.44465 6.71852L8.25063 4.84848L8.25064 4.84849L8.25187 4.84556C8.2748 4.79091 8.3325 4.75094 8.40784 4.75094C8.48319 4.75094 8.54089 4.79091 8.56382 4.84556L8.5638 4.84557L8.56506 4.84848L9.37103 6.71852L9.3709 6.71858L9.37493 6.72722C9.47233 6.93618 9.67962 7.10227 9.93979 7.10757L12.0398 7.24787L12.0409 7.24794C12.0951 7.25138 12.1438 7.28789 12.1651 7.34869C12.1752 7.37746 12.1756 7.40393 12.1704 7.42455C12.1658 7.44248 12.1553 7.46412 12.1274 7.48679L10.4769 8.77907Z"
                                  fill="#FEEE00"
                                  stroke="#333848"
                                  stroke-width="0.652272"
                                ></path>
                              </svg>
                              &nbsp;
                            </span>
                            {t("inner_pages.detail_page.HowToReviewProd")}
                          </Typography>
                          <Typography sx={{ px: 1 }}>
                            {t("inner_pages.detail_page.howToReviewAns")}
                          </Typography>

                          <Typography variant="h6" sx={{ px: 1, mt: 4 }}>
                            <span>
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="noonLogo"
                              >
                                <path
                                  d="M7.99804 16.0276C12.132 16.0276 15.4961 12.6635 15.4961 8.52955C15.4961 4.37051 12.1296 0.990479 7.99804 0.990479C3.86646 0.990479 0.499989 4.37051 0.499989 8.52955C0.499989 12.6635 3.86406 16.0276 7.99804 16.0276Z"
                                  fill="#FEEE00"
                                  stroke="#404554"
                                ></path>
                                <path
                                  d="M9.40092 4.09473C9.01233 3.93929 8.59265 3.86157 8.17297 3.86157C7.92428 3.86157 7.67558 3.89266 7.42688 3.93929L6.42432 5.66463C6.83622 5.43925 7.29476 5.33044 7.76884 5.33822C8.0564 5.33822 8.33618 5.37708 8.6082 5.45479L9.40092 4.09473Z"
                                  fill="#404554"
                                ></path>
                                <path
                                  d="M3.37769 8.73421C3.37769 11.2212 5.49162 13.1564 7.95528 13.1564C10.5355 13.1564 12.6184 11.0735 12.6184 8.53992C12.6184 7.0555 11.9034 5.74984 10.8386 4.87939L10.0614 6.22392C10.7454 6.8068 11.1417 7.6617 11.1417 8.56323C11.1417 10.2886 9.71171 11.703 7.95528 11.703C6.19885 11.703 4.77661 10.2808 4.77661 8.52437C4.77661 8.52437 4.77661 8.52437 4.77661 8.5166C4.77661 8.30676 4.79216 8.09693 4.83101 7.89486L3.37769 8.73421Z"
                                  fill="#404554"
                                ></path>
                              </svg>
                              &nbsp;
                            </span>
                            {t("inner_pages.detail_page.WhereReviewComeFrom")}
                          </Typography>
                          <Typography sx={{ px: 1 }}>
                            {t(
                              "inner_pages.detail_page.WhereReviewComeFromAns"
                            )}
                          </Typography>
                        </div>
                      </Box>
                      {/* ) : (
                      ""
                    )} */}
                    </Grid>

                    <Grid
                      item
                      xl={8}
                      lg={8}
                      md={6}
                      sm={12}
                      xs={12}
                      className="dsc-block reviews-details"
                    >
                      <div className="customer-reviews-wrap">
                        <div>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                            sx={{ px: 1 }}
                          >
                            <span style={{ paddingRight: "6px" }}>
                              {" "}
                              {detailsData?.review_count}{" "}
                            </span>
                            {t("inner_pages.detail_page.custReview")}
                          </Typography>
                        </div>
                        {/* <div className="customer-reviews-filter">
                        
                          <div>
                          <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChangeee}
                            aria-label="Platform">
                            <ToggleButton value="web">All</ToggleButton>
                            <ToggleButton value="android">English</ToggleButton>
                            <ToggleButton value="ios">عربي</ToggleButton>
                          </ToggleButtonGroup>
                          </div>
                          <div>
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                  value={age}
                                  onChange={handleChangee}                                  
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={'Most Helpful'}>Most Helpful</MenuItem>
                                  <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                                  <MenuItem value={'Highest Rating'}>Highest Rating</MenuItem>
                                  <MenuItem value={'Lowest Rating'}>Lowest Rating</MenuItem>
                                </Select>
                              </FormControl>
                          </div>
                      </div> */}
                      </div>

                      <Divider />
                      <Box sx={{ px: 2 }}>
                        {detailsData?.reviews?.items?.map((item: any) => {
                          const nameChar = item?.nickname?.charAt(0);
                          return (
                            <>
                              <div className="customer-reviews-list">
                                <div className="customer-reviews-list-wrap">
                                  <div>
                                    <IconButton
                                      sx={{ p: 0 }}
                                      // classes={{ label: classes.iconButton }}
                                      size="medium"
                                      aria-label="account of current user"
                                      // aria-controls={menuId}
                                      aria-haspopup="true"
                                      // onClick={handleOpenUserMenu}
                                      color="inherit"
                                    >
                                      <Box
                                        sx={{
                                          display: {
                                            md: "flex",
                                            height: "44px",
                                            width: "44px",
                                            backgroundColor: "#cbe5f8",
                                            borderRadius: "100%",
                                            padding: "0",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          },
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: "#0e5284",
                                          }}
                                        >
                                          {nameChar}
                                        </span>
                                      </Box>
                                    </IconButton>
                                  </div>
                                  <div className="cust-name">
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="h6"
                                      sx={{ px: 1 }}
                                    >
                                      {item?.nickname}
                                    </Typography>
                                    <p>{item?.created_at}</p>
                                  </div>
                                  <div className="verified-user">
                                    <span>
                                      <CheckCircleIcon />
                                    </span>
                                    <span>Verified Purchase</span>
                                  </div>
                                </div>
                                <div>
                                  <ReactStars
                                    size={18}
                                    isHalf={true}
                                    activeColor="#38ae04"
                                    value={item?.average_rating}
                                    activeColors={[
                                      "#38ae04",
                                      "#38ae04",
                                      "#38ae04",
                                      "#38ae04",
                                      "#38ae04",
                                    ]}
                                  />
                                </div>
                                <div className="review-box">
                                  <p>
                                    <b>{item?.summary}</b>
                                  </p>
                                  <p>{item?.text}</p>

                                  {/* <Button variant="outlined" startIcon={<ThumbUpOffAltIcon />}> Helpful (252)</Button> */}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                  {/* <div className="view-all-wrap">
                  <Button>  View All reviews</Button>
              
                </div> */}
                  {/* <CustomerViewProduct/> */}
                </Box>
              </div>
            </Container>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
export default DetailsPage;
