import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import GridUn from "@mui/material/Unstable_Grid2";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
import { useParams } from "react-router";
import theme from "../../../theme";
import Popover from "@mui/material/Popover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import "./Domestic-machine.scss";
import { CountryContext } from "../MainComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactStars from "react-rating-star-with-type";
import Pagination from "@mui/material/Pagination";
import Loader from "../Layout/loader";

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.407 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
      stroke="#118FA7"
      stroke-width="1.5"
    />
  </svg>
);
const dropdownArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
  >
    <path
      d="M7.03271 0.212554L0.178261 7.84997C0.0638337 7.9774 0 8.14671 0 8.32277C0 8.49883 0.0638337 8.66813 0.178261 8.79556L0.186007 8.80379C0.241479 8.86578 0.30825 8.91514 0.382259 8.94887C0.456268 8.9826 0.535966 9 0.616507 9C0.697048 9 0.776747 8.9826 0.850755 8.94887C0.924764 8.91514 0.991535 8.86578 1.04701 8.80379L7.50129 1.61176L13.953 8.80379C14.0085 8.86578 14.0752 8.91514 14.1492 8.94887C14.2233 8.9826 14.303 9 14.3835 9C14.464 9 14.5437 8.9826 14.6177 8.94887C14.6917 8.91514 14.7585 8.86578 14.814 8.80379L14.8217 8.79556C14.9362 8.66813 15 8.49883 15 8.32277C15 8.14671 14.9362 7.9774 14.8217 7.84997L7.96729 0.212554C7.90701 0.145386 7.83451 0.0919133 7.75419 0.0553766C7.67386 0.0188398 7.58739 0 7.5 0C7.41261 0 7.32614 0.0188398 7.24581 0.0553766C7.16549 0.0919133 7.09299 0.145386 7.03271 0.212554Z"
      fill="#5C5959"
    />
  </svg>
);

const responsive_one: any = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const responsive: any = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 10,
    slidesToSlide: 3, // optional, default to 1.
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function DomesticMachine({ onLoad, isCountry }: any) {
  let minFilterPrice: number = 0;
  let maxFilterPrice: number = 0;
  let filterPriceRangeVal: any;
  const params = useParams();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [selectedMenufacturer, setSelectedMenufacturer] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { country, setCountry, store, setStore } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceValue, setPriceValue] = useState<number[]>([
    minFilterPrice,
    maxFilterPrice,
  ]);
  const [fetchProd, setFetchPro] = useState(false);
  const [searchData, setSearchData] = useState<any>([]);
  const [filteredPro, setFilteredPro] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [totalPage, setTotalPage] = useState<any>()
  const open = Boolean(anchorEl);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPriceValue(newValue as number[]);
  };
  function valuetext(value: number) {
    filterPriceRangeVal = `${value}AED`;
    return `${value}AED`;
  }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [filteredPro]);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  //   const { data: sliderRes, refetch } = useQuery(
  //     queries.homeQueries.GET_TOPSLIDER,
  //     {
  //       context: {
  //         headers: customHeader,
  //       },
  //     }
  //   );
  const url = location?.pathname?.split("/category");
  const urlKey = url[url.length - 1];
  const { data: categoryid, refetch: refatchId } = useQuery(
    queries.cartQueries.GET_CATEGORY_URL,
    {
      variables: { url: urlKey },
      // context: {
      //   headers: customHeader,
      // },
    }
  );

  useEffect(() => {
    refatchId();
  }, [urlKey, refatchId]);

  var category_id = categoryid?.urlResolver?.id?.toString();
  const {
    loading: catLoading,
    data: categoryProduct,
    refetch,
  } = useQuery(queries.productQueries.SIMPLE_CATEGORY_PRODUCTLIST, {
    variables: { categoryId: category_id, currentPage: currentPage },
    context: {
      headers: customHeader,
    },
  });
  const {
    loading: catLoader,
    data: catRes,
    refetch: refetchCatData,
  } = useQuery(queries.homeQueries.GET_CATEGORY_SLIDER, {
    variables: { categoryId: category_id },
    context: {
      headers: customHeader,
    },
  });
  const catData = catRes?.getSubCategoriesData;
  useEffect(() => {
    refetch();
  }, [category_id, refetch, currentPage]);
  let categoryProductList = categoryProduct?.products?.items;
  const currnetPage = categoryProduct?.products?.page_info?.current_page;
  const totalPages = categoryProduct?.products?.page_info?.total_pages;
  const filterAggregationProducts =
    categoryProduct && categoryProduct?.products?.aggregations;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;
  // const customHeader = {
  //     store: country,
  //   };
  const {
    loading: filterLoading,
    error: filterError,
    data: filterDataRes,
    refetch: refetchProd,
  } = useQuery(queries.productQueries.GET_PRODUCTS_FILTER, {
    variables: {
      categoryId: category_id,
      from: priceValue[0],
      to: priceValue[1],
      // categoryId : '1780',
      manufacturerName: selectedMenufacturer,
      pageSize: 25,
    },
  });
  const filterProducts = filterDataRes && filterDataRes?.products?.aggregations;
  const filterProductItems = filterDataRes && filterDataRes?.products?.items;

  useEffect(() => {
    if (!fetchProd) {
      const currnetPages = categoryProduct?.products?.page_info?.current_page;
      const totalPages = categoryProduct?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages)
      }
    } else if (filterProductItems?.length > 0) {
      const currnetPages = filterDataRes?.products?.page_info?.current_page;
      const totalPages = filterDataRes?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      setCurrentPage(currnetPages)
      setSelectedPage(currnetPages)
      setFilteredPro(filterProductItems);
    }
  }, [filterProductItems, categoryProductList]);

  useEffect(() => {
    refetchProd();
  }, [fetchProd, category_id, refetchProd]);

  // useEffect(() => {
  //   setSearchData(productFilter);
  //   if (filterProductItems) {
  //     productFilter = filterDataRes?.products?.item;
  //     // setSearchData(filterDataRes?.products?.item);
  //     setAnchorEl(null);
  //   }
  // }, [filterProductItems]);

  filterAggregationProducts &&
    filterAggregationProducts.map((data: any) => {
      if (data?.attribute_code === "price") {
        const minPriceProduct = data?.options.reduce(
          (min: any, currentProduct: any) => {
            return parseInt(currentProduct.value) < parseInt(min.value)
              ? currentProduct
              : min;
          },
          data?.options[0]
        );
        const maxPriceProduct = data?.options.reduce(
          (max: any, currentProduct: any) => {
            return parseInt(currentProduct.value) > parseInt(max.value)
              ? currentProduct
              : max;
          },
          data?.options[0]
        );
        maxFilterPrice = parseInt(maxPriceProduct?.value);
        minFilterPrice = parseInt(minPriceProduct?.value);
      }
    });
  useEffect(() => {
    setPriceValue([minFilterPrice, maxFilterPrice]);
  }, [minFilterPrice, maxFilterPrice]);

  function handleChipClick(item: any) {
    // setSelectedMenufacturer(item?.value);
    setFetchPro(true);
    setSelectedMenufacturer([...selectedMenufacturer, item?.value]);
  }

  // const hanldeFilter = () => {
  //   setFetchPro(true);
  //   handleClose();
  // };

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  return (
    <div className="main-content">
      {/* <Box>
        <div className="top-slider -hero-slider">
          {sliderData ? (
            <Carousel
              className="top-offer-slider"
              responsive={responsive_one}
              swipeable={true}
              autoPlay={false}
              transitionDuration={500}
              autoPlaySpeed={5000}
              arrows={true}
              infinite={true}
              centerMode={false}
              ssr={true}
              showDots={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              slidesToSlide={1}
            >
              {sliderData.map((data: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="image-item"
                    style={{
                      backgroundImage: `url(${
                        isSmallScreen ? data?.image_mobile : data?.image
                      })`,
                    }}
                  ></div>
                );
              })}
            </Carousel>
          ) : (
            ""
          )}
        </div>
      </Box> */}
      {/* {catLoading ? (
        <Loader />
      ) : ( */}
      <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="category-home-grid"
            >
              <CardHeader
                className="section-title"
                style={{ paddingTop: 0 }}
                titleTypographyProps={{ variant: "h5" }}
                title={t("main.category-title")}
              />
              <CardContent sx={{ py: 0 }}>
                {catData ? (
                  <Carousel
                    className="category-home-grid-slider"
                    responsive={responsive}
                    swipeable={true}
                    autoPlay={false}
                    centerMode={false}
                    arrows={false}
                    infinite={true}
                    ssr={true}
                    showDots={false}
                    slidesToSlide={1}
                    deviceType={""}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
                    {catData.map((data: any, index: any) => {
                      return (
                        <div className="category-box">
                          <Link to={`/${store}/category/${data?.url_key}`}>
                            <div className="category-box-img">
                              <img
                                className="img-cover"
                                draggable={false}
                                style={{ position: "relative" }}
                                src={data.image}
                                alt={data.name}
                              />
                            </div>
                            <div className="category-box-title">
                              <span>
                                {data.name}{" "}
                                {isSmallScreen
                                  ? data.image_mobile
                                  : data.image_mobile}
                              </span>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  ""
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <div>
        <Box sx={{ pt: 5 }}>
          <Card
            sx={{ maxWidth: "100%", boxShadow: 0 }}
            className="topsell-machine-grid"
          >
            <Box sx={{ minWidth: 120, px: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={4} lg={3}>
                  <div className="filter-box">
                    <Box sx={{ minWidth: "100px" }}>
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        sx={{
                          bgcolor: "#fff",
                          boxShadow: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                        className="filter-btn"
                        endIcon={
                          isDropdownOpen ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )
                        }
                      >
                        <span
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {filterIcon}
                        </span>
                        {t("inner_pages.domestic_machine.filter")}
                      </Button>
                      <Popover
                        className="filter-popver"
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6} md={6}>
                                {filterAggregationProducts &&
                                  filterAggregationProducts.map((data: any) => {
                                    return data?.attribute_code ===
                                      "manufacturer" ? (
                                      <Accordion
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "20px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            border: "1px solid #66E5FF",
                                            background: "#10CDF2",
                                          }}
                                        >
                                          <Typography>
                                            {t(
                                              "inner_pages.domestic_machine.menufacturer"
                                            )}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          sx={{
                                            border: "1px solid #E5E3E3",
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              flexWrap: "wrap",
                                              gap: "10px",
                                            }}
                                          >
                                            {data?.options.map((item: any) => {
                                              return (
                                                <Chip
                                                  key={item?.value}
                                                  onClick={() =>
                                                    handleChipClick(item)
                                                  }
                                                  label={`${item?.label} (${item?.count}) fg`}
                                                  className={`filter-chip ${
                                                    selectedMenufacturer.includes(
                                                      item?.value
                                                    )
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  component="a"
                                                  href="#"
                                                  clickable
                                                  variant="outlined"
                                                  sx={{
                                                    border: "1px solid #EFEFEF",
                                                    background: "#FAFAFA",
                                                    px: 4,
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              );
                                            })}
                                          </Stack>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : (
                                      ""
                                    );
                                  })}
                              </Grid>
                              <Grid item xs={12} lg={6} md={6}>
                                {filterAggregationProducts &&
                                  filterAggregationProducts.map((data: any) => {
                                    return data?.attribute_code === "price" ? (
                                      <Accordion
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "20px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            border: "1px solid #66E5FF",
                                            background: "#10CDF2",
                                          }}
                                        >
                                          <Typography>
                                            {t(
                                              "inner_pages.domestic_machine.price"
                                            )}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          sx={{
                                            border: "1px solid #E5E3E3",
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{ mb: 3 }}
                                          >
                                            {/* {data?.options.map((item:any) => { */}
                                            {/* return ( */}
                                            <>
                                              <Chip
                                                sx={{
                                                  border: "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 4,
                                                  borderRadius: "5px",
                                                }}
                                                label={`${
                                                  filterDataRes?.products
                                                    ?.aggregations &&
                                                  filterDataRes?.products
                                                    ?.aggregations[0]?.currency
                                                } ${minFilterPrice}`}
                                                variant="outlined"
                                              />
                                              <Chip
                                                sx={{
                                                  border: "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 4,
                                                  borderRadius: "5px",
                                                }}
                                                label={`${
                                                  filterDataRes?.products
                                                    ?.aggregations &&
                                                  filterDataRes?.products
                                                    ?.aggregations[0]?.currency
                                                } ${maxFilterPrice}`}
                                                variant="outlined"
                                              />
                                            </>
                                            {/* ); */}
                                            {/* })} */}
                                          </Stack>
                                          <Box sx={{ width: 300 }}>
                                            <Slider
                                              getAriaLabel={() => "Price range"}
                                              value={priceValue}
                                              onChange={handleSliderChange}
                                              valueLabelDisplay="auto"
                                              getAriaValueText={valuetext}
                                              min={minFilterPrice}
                                              max={maxFilterPrice}
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>
                                              {t(
                                                "inner_pages.domestic_machine.2365-products"
                                              )}
                                            </span>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : (
                                      ""
                                    );
                                  })}
                              </Grid>
                              {/* <Button
                                className="save-btn"
                                variant="contained"
                                disableElevation
                                onClick={hanldeFilter}
                              >
                                {t("inner_pages.domestic_machine.save_btn")}
                              </Button> */}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Popover>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <CardContent className="category_list">
              {filteredPro ? (
                <GridUn
                  container
                  rowSpacing={5}
                  columnSpacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 12 }}
                >
                  {Array.from(Array(filteredPro?.length)).map(
                    (_, index) => (
                      <GridUn
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        key={filteredPro[index].sku}
                      >
                        <Link
                          to={`/${store}/${filteredPro[index]?.url_key}.html`}
                        >
                          <Item
                            className="jeb-cust-slide"
                            sx={{ boxShadow: 0 }}
                          >
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: 0,
                                border: "1px solid #e1e1e1",
                              }}
                            >
                              <div className="slider-img-box">
                                <CardMedia
                                  component="img"
                                  alt="green iguana"
                                  height="100%"
                                  image={
                                    filteredPro[index]?.image?.small_url
                                  }
                                  // image={
                                  //   isSmallScreen
                                  //     ? filteredPro[index]?.small_image?.small_url
                                  //     : filteredPro[index].image.small_url
                                  // }
                                />

                                <div className="offer-main-box">
                                  {/* <div className="offer-space-box">
                                                        <div className="seller-box">
                                                            <div>offer_name</div>
                                                        </div>
                                                        <div className="offer-box">
                                                            <div>10%</div>
                                                        </div>
                                                    </div> */}
                                </div>
                              </div>
                              <CardContent
                                sx={{
                                  textAlign: "start",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  color="text.dark"
                                  className="top-sell-title"
                                >
                                  {filteredPro[index].name}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  color="text.dark"
                                  className="top-sell-amount"
                                >
                                  {
                                    filteredPro[index].price_range
                                      ?.minimum_price?.regular_price?.currency
                                  }
                                  {
                                    filteredPro[index].price_range
                                      ?.minimum_price?.regular_price?.value
                                  }
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  color="text.secondary"
                                  component="del"
                                  className="top-actual_amount"
                                >
                                  {filteredPro[index].actual_amount}
                                </Typography>
                                {filteredPro?.configurable_options && (
                                  <div className="color-switch-list">
                                    {filteredPro
                                      ?.configurable_options[0]?.values
                                      ?.length > 2
                                      ? filteredPro?.configurable_options[0]?.values?.map(
                                          (item: any, index: any) => {
                                            const restCount =
                                              filteredPro
                                                ?.configurable_options[0]
                                                ?.values?.length - 4;
                                            // const a = filteredPro[index].colors;
                                            if (index === 4) {
                                              return (
                                                <Button size="small">
                                                  <div className="color-switch">
                                                    +{restCount}
                                                  </div>
                                                </Button>
                                              );
                                            } else if (index < 4) {
                                              return (
                                                <Button size="small">
                                                  <div
                                                    className="color-switch"
                                                    style={{
                                                      backgroundColor:
                                                        item?.swatch_data
                                                          ?.value,
                                                    }}
                                                  ></div>
                                                </Button>
                                              );
                                            }
                                          }
                                        )
                                      : filteredPro?.configurable_options[0]?.values.map(
                                          (colors: any) => {
                                            return (
                                              <Button size="small">
                                                <div
                                                  className="color-switch"
                                                  style={{
                                                    backgroundColor:
                                                      colors?.swatch_data
                                                        ?.value,
                                                  }}
                                                ></div>
                                              </Button>
                                            );
                                          }
                                        )}
                                  </div>
                                )}
                                <div className="stars-block">
                                  {filteredPro?.rating_summary > 0 && (
                                    <ReactStars
                                      size={18}
                                      isHalf={true}
                                      activeColor="#DD8C27"
                                      value={
                                        filteredPro?.rating_summary
                                      }
                                      activeColors={[
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                        "#DD8C27",
                                      ]}
                                    />
                                  )}
                                  {filteredPro?.review_count > 0 && (
                                    <div>
                                      <span>
                                        {filteredPro?.review_count}{" "}
                                        Review
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  color="text.dark"
                                  className="top-vat"
                                >
                                  {country === "in"
                                    ? t("main.inclusive_of_gst")
                                    : t("main.inclusive_of_vat")}
                                </Typography>

                                {/* <div className="color-switch-list">

                                                    {topSellProd[index].colors.length > 2
                                                        ? topSellProd[index].colors.map((item: any, index: any) => {
                                                            const restCount =
                                                                topSellProd[index].colors.length - 2;
                                                            const a = topSellProd[index].colors;
                                                            if (index === 2) {
                                                                return (
                                                                    <Button size="small">
                                                                        <div className="color-switch">
                                                                            +{restCount}
                                                                        </div>
                                                                    </Button>
                                                                );
                                                            } else if (index < 2) {
                                                                return (
                                                                    <Button size="small">
                                                                        <div
                                                                            className="color-switch"
                                                                            style={{ backgroundColor: item }}
                                                                        ></div>
                                                                    </Button>
                                                                );
                                                            }
                                                        })
                                                        : topSellProd[index].colors.map((colors: any) => {
                                                            return (
                                                                <Button size="small">
                                                                    <div
                                                                        className="color-switch"
                                                                        style={{ backgroundColor: colors }}
                                                                    ></div>
                                                                </Button>
                                                            );
                                                        })}
                                                </div> */}
                              </CardContent>
                            </Card>
                          </Item>
                        </Link>
                      </GridUn>
                    )
                  )}
                </GridUn>
              ) : (
              ""
              )}
            </CardContent>
          </Card>
        </Box>
        {totalPage && (
          <Box sx={{ pt: 5 }}>
            <Stack spacing={2}>
              <Pagination
                count={totalPage ? totalPage : 10}
                shape="rounded"
                onChange={handlePageChange}
                defaultPage={selectedPage}
              />
            </Stack>
          </Box>
        )}
      </div>
      {/* )} */}
    </div>
  );
}
export default DomesticMachine;
