import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

function AccountInfo() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F0F2F2",
              mb: 4,
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontWeight: "600", fontSize: "20px" }}
            >
              {t("buyer_dashboard.accountInfo.newsletter")}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              bgcolor: "#F7F6F6",
              mb: 4,
              border: "1px solid #D2CDCD",
              p: 2,
              textAlign: "initial",
            }}
          >
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "18px", fontWeight: "500" }}
            >
              {t("buyer_dashboard.accountInfo.subscription")}
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <FormControlLabel
              control={<Checkbox />}
              label="Allow remote shopping assistance"
            />
          </Box>
          <Box
            sx={{ width: "100%", bgcolor: "#fff", mb: 4, textAlign: "initial" }}
          >
            <Button variant="contained" disableElevation>
              {t("buyer_dashboard.accountInfo.save_btn")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
export default AccountInfo;
