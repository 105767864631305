import React, { useState, useContext, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import CardHeader from "@mui/material/CardHeader";
import { FormControl, InputLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { CountryContext, mainContext } from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import AddNewAddress from "./AddNewAddress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import CCAvenueForm from "../../../components/ccevenueForm/CcevenueForm";
import { styled, Theme } from "@mui/system";
import Modal from "../../../components/Modal/Modal";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={className + (requestOpen ? " open" : " close")}
    >
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;
const grey = {
  50: "#f6f8fa",
  200: "#d0d7de",
  500: "#6e7781",
  700: "#424a53",
  900: "#24292f",
};
const PopupBody = styled("div")(
  ({ theme }: { theme: Theme }) => `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    border-radius: 8px;
    box-shadow: ${
      theme.palette.mode === "dark"
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);

declare const window: any;
function Checkout(props: any) {
  const { store } = useContext(CountryContext);
  let defaultBillingData: any;
  // let defaultBillingState:any = [];
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [custCountry, setCustCountry] = useState(String());
  const [custState, setCustState] = useState(String());
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [paymentvalue, setPaymentValue] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const loggedInToken = localStorage.getItem("loggedInCust");

  const [checkQty, setCheckQty] = useState("1");
  const [onLoad, setOnLoad] = useState(true);
  const [showPwd, setShowPwd] = useState(false);

  const [open, setOpen] = useState(false);
  const [successUrl, setSuccessUrl] = useState("");
  const [shippingToggle, setShipingToggle] = useState(false);
  const [procedToPayToggle, setProcedToPayToggle] = useState(false);
  const [shippingSuccess, setShippingSuccess] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState({
    bill_region: "DL",
    region_id: 578,
  });
  const [telerToken, setTelerToken] = useState("");
  const [ccevenueRes, setCcevenueRes] = useState("");
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [isOpenFPwd, setIsOpenFPwd] = useState(false);
  const [isAnchor, setIsAnchor] = useState(false);

  const Values = {
    telr_token: telerToken,
    store_id: "20906",
    auth_key: "jFBzR-ZJzq~524k5",
    amount: "100",
    currency: "AED",
    bill_fname: "John",
    bill_sname: "Doe",
    bill_addr1: "Test Address Line 1",
    bill_addr2: "Test Address Line 2",
    bill_city: "Dubai",
    bill_region: "DL",
    region_id: 578,
    bill_zip: "11111",
    bill_country: selectedCountry,
    bill_email: "customer_email@test.com",
    bill_tel: "9756565654",
    pin_code: "415277",
  };

  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store]);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const {
    loading: logoLoader,
    data: logoRes,
    refetch: refetchLogo,
  } = useQuery(queries.headerQueries.GET_LOGO, {
    context: {
      headers: customHeader,
    },
  });

  const logoData = logoRes?.storeConfig;
  const logoUrl =
  logoData?.secure_base_media_url + "logo/" + logoData?.header_logo_src;
  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };
  const store_code = localStorage.getItem("store_code");
  const handleOpenModal = (isEdit: any) => {
    if (isEdit) {
      setIsEdit(isEdit);
    } else {
      setIsEdit("");
    }
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setIsEdit("");
  };
  const { quest_cart_id, setCartCount, set_quest_cart_id } =
    useContext(mainContext);
  let subtotal: any = "";
  let getQty: any = "";
  let subTotalAmount: number = 0;

  const shippingMethods = [
    {
      carrier_code: "",
      method_code: "",
    },
  ];

  const handledCheckQty = (event: SelectChangeEvent) => {
    setCheckQty(event.target.value as string);
  };

  const {
    loading: cartDetailsLoading,
    error: cartDetailsErr,
    data: cartDetailsRes,
  } = useQuery(queries.cartQueries.GET_CART_DETAILS, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });
  const cartDetails = cartDetailsRes?.cart;
  cartDetails?.items.map((data: any) => {
    getQty = data?.quantity;
    subtotal = {
      currency: data?.prices?.row_total?.currency,
      amount: data?.prices?.row_total?.value,
    };
    let cartSubTotal = subtotal?.amount * getQty;
    subTotalAmount += cartSubTotal;
  });

  const {
    loading: methodLoading,
    error: methodError,
    data: methodRes,
  } = useQuery(queries.cartQueries.SHIPPING_BILLING_INFO, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });

  const {
    loading: shippingPayLoading,
    error: shippingPayError,
    data: shippingPayRes,
  } = useQuery(queries.cartQueries.SHIPPING_AND_PAYMENT_METHOD, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });
  shippingMethods[0].carrier_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.carrier_code;
  shippingMethods[0].method_code =
    shippingPayRes?.cart?.shipping_addresses[0]?.available_shipping_methods[0]?.method_code;

  useEffect(() => {
    if (shippingToggle === false) {
      if (
        shippingMethods[0]?.carrier_code !== "" &&
        shippingMethods[0]?.carrier_code !== undefined &&
        shippingMethods[0]?.method_code !== undefined &&
        shippingMethods[0].method_code !== ""
      ) {
        handleSetShippingMethod();
        setShippingSuccess(true);
        setShipingToggle(true);
      }
    }
  }, [shippingMethods]);

  useEffect(() => {
    // handleSetPaymentMethod(paymentvalue);
    if (shippingSuccess) {
      SetOrderPaymentMethod({
        variables: {
          cartId: quest_cart_id,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
    }
  }, [paymentvalue, shippingSuccess]);

  const [setUestEmail] = useMutation(
    mutations.addToCartMutation.SET_GUEST_EMAIL,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const {
    loading: availablePayMethodLoading,
    error: availablePayMethodError,
    data: availablePayMethodRes,
  } = useQuery(queries.orderQueries.GET_AVAILABLE_PAYMENT_METHODS_QUERY, {
    variables: {
      cartId: quest_cart_id,
    },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });

  const { loading: custDetailsLoader, data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  let defaultBilling = custDetailsDataRes?.customer?.addresses.filter(function (
    val: any
  ) {
    return val?.default_shipping === true;
  });
  const handledCustState = (event: SelectChangeEvent) => {
    setCustState(event.target.value);
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [
    generateCustomerToken,
    {
      data: generateCustData,
      loading: generateCustLoader,
      error: generateCustError,
    },
  ] = useMutation(mutations.loginMutation.LOGIN_MUTATION, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });

  const handleGenerateToken = async (event: any) => {
    event.preventDefault();
    const data = formData;
    try {
      const response = await generateCustomerToken({
        variables: data,
      });
      generateCustData = response.data;
      setResError("");
      setShowErrorAlert(false);
      localStorage.setItem(
        "loggedInCust",
        JSON.stringify(generateCustData.generateCustomerToken.token)
      );
      navigate(`/${store_code}/checkout`);
    } catch (err: any) {
      if (err) {
        setResError(err?.message);
        setShowErrorAlert(true);
      }
    }
  };
  const {
    loading: countryRegLoading,
    error: countryRegError,
    data: countryRegRes,
  } = useQuery(queries.configurationQueries.GET_COUNTRIES, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store_code,
      },
    },
  });

  const { loading: storeLoading, data: storeRes } = useQuery(
    queries.headerQueries.GET_STORES,
    {
      context: {
        headers: customHeader,
      },
    }
  );

  // const a = storeRes?.getStores;

  // useEffect(() => {
  //   if (a && a.length > 0) {
  //     const b = a?.filter((item: any) => {
  //       const c = item?.store_code?.split("_");
  //       const d = store.split("_");
  //       if (c[0] === d[0]) {
  //         // setCustCountry(item.store_code)
  //         return item;
  //       }
  //     });
  //     // setCustCountry(b[0]?.country);
  //   }
  // }, [a]);

  const selectedCountryData = countryRegRes?.countries?.find(
    (data: any) => data?.two_letter_abbreviation === selectedCountry
  );
  useEffect(() => {
    const region = countryRegRes?.countries?.filter(
      (item: any) => item?.two_letter_abbreviation === selectedCountry
    );
    setSelectedRegion({
      bill_region: selectedCountryData?.available_regions[0]?.code,
      region_id: selectedCountryData?.available_regions[0]?.id,
    });
    setCustCountry(selectedCountryData?.two_letter_abbreviation);
    // setRegionTwoWords(selectedCountryData?.available_regions[0]?.code);
    if (region && region.length > 0) {
      addBillingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: Values.bill_fname,
          lastname: Values.bill_sname,
          company: "jeblz",
          street: Values.bill_addr1,
          city: Values.bill_city,
          region: selectedCountryData?.available_regions[0]?.code,
          regionId: selectedCountryData?.available_regions[0]?.id,
          postCode: store_code === "in" ? Values.pin_code : Values.bill_zip,
          country_code: selectedCountryData?.two_letter_abbreviation,
          telephone: Values.bill_tel,
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
      addShipingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: Values.bill_fname,
          lastname: Values.bill_sname,
          company: "jeblz",
          street: Values.bill_addr1,
          city: Values.bill_city,
          region: selectedCountryData?.available_regions[0]?.code,
          regionId: selectedCountryData?.available_regions[0]?.id,
          postCode: store_code === "in" ? Values.pin_code : Values.bill_zip,
          country_code: selectedCountryData?.two_letter_abbreviation,
          telephone: Values.bill_tel,
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
    }
  }, [custCountry, countryRegRes]);

  const countries = countryRegRes?.countries;

  var defaultBillingCountry = countries?.filter(function (val: any) {
    return val?.two_letter_abbreviation === val.two_letter_abbreviation;
  });

  var defaultBillingState =
    defaultBillingCountry && defaultBillingCountry[0]?.available_regions;

  const [placeOrderMutation] = useMutation(
    mutations.orderMutation.PLACE_ORDER_MUTATION,
    {
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );
  const [addBillingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_BILLING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );
  const [addShipingAddrMutation] = useMutation(
    mutations.orderMutation.ADD_SHIPPING_ADDR,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const [setBillingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_BILLING_ADDRESS_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const [setShippingAddrCustMutation] = useMutation(
    mutations.orderMutation.SET_SHIPPING_ADDR_CUST,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  useEffect(() => {
    if (onLoad && defaultBilling && defaultBilling.length > 0) {
      setOnLoad(false);
    }
  }, [defaultBilling]);

  const handlePlaceOrder = async () => {
    await placeOrderMutation({
      variables: {
        cartId: quest_cart_id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    })
      .then((result) => {
        localStorage.setItem(
          "orderId",
          result.data.placeOrder.order.order_number
        );
        localStorage.removeItem("quest_cart_id");
        // set_quest_cart_id("");
        // setCartCount(0);
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });
  };

  const [SetOrderPaymentMethod] = useMutation(
    mutations.orderMutation.SET_ORDER_Add_PAYMENT_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const handleSetPaymentMethod = async (value: any) => {
    const cartId = quest_cart_id;
    const paymentCode = value;

    try {
      const response = await SetOrderPaymentMethod({
        variables: {
          cartId: cartId,
          paymentCode: paymentvalue,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
    } catch (error) {
      console.error("Error setting payment method:", error);
    }
  };

  const paymentMethodRes = useMemo(() => {
    if (availablePayMethodRes?.cart?.available_payment_methods) {
      setPaymentValue(
        availablePayMethodRes?.cart?.available_payment_methods[0]?.code
      );
      return availablePayMethodRes?.cart?.available_payment_methods;
    }
  }, [availablePayMethodRes]);

  const cartId = quest_cart_id;

  const [setShippingMethod] = useMutation(
    mutations.orderMutation.SET_SHIPPING_METHOD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const handleSetShippingMethod = async () => {
    try {
      const response = await setShippingMethod({
        variables: {
          cartId: cartId,
          shippingMethods: shippingMethods,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
    } catch (error) {
      console.error("Error setting shipping method:", error);
    }
  };

  const handlePaymentChange = (event: any) => {
    if (event.target.value !== undefined || event.target.value !== null) {
      setPaymentValue(event.target.value);
    }
    if (event.target.value === "telr_telrpayments") {
      // navigate(`/${store}/telrPayment`);
    } else {
      handleSetPaymentMethod(event.target.value);
      // handleSetShippingMethod();
    }
  };

  const handleSetShippingAdd = (addId: any) => {
    const data = custDetailsDataRes?.customer?.addresses.filter(
      (item: any) => item.id === addId
    );
    addBillingAddrMutation({
      variables: {
        cartId: quest_cart_id,
        firstname: data[0].firstname,
        lastname: data[0].lastname,
        company: data[0].company,
        street: data[0].street,
        city: data[0].city,
        region: data[0].region.region,
        regionId: selectedCountryData?.available_regions[0]?.id,
        postCode: data[0].postcode,
        country_code: data[0].country_code,
        telephone: data[0].telephone,
        save_in_address_book: true,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    })
      .then((result) => {})
      .catch((error) => {
        console.error("Error placing order:", error);
      });

    addShipingAddrMutation({
      variables: {
        cartId: quest_cart_id,
        firstname: data[0].firstname,
        lastname: data[0].lastname,
        company: data[0].company,
        street: data[0].street,
        city: data[0].city,
        region: data[0].region.region,
        regionId: selectedCountryData?.available_regions[0]?.id,
        postCode: data[0].postcode,
        country_code: data[0].country_code,
        telephone: data[0].telephone,
        save_in_address_book: false,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    })
      .then((result) => {})
      .catch((error) => {
        console.error("Error placing order:", error);
      });

    setBillingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    });
    setShippingAddrCustMutation({
      variables: {
        cart_id: quest_cart_id,
        customer_address_id: addId,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    });
  };

  //-------- teller -------
  var onTokenReceive = function (telrToken: any) {
    /* Note: Include the received card token from iframe in payment form & send as ivp_ctoken parameter */
    setTelerToken(telrToken);
  };

  useEffect(() => {
    if (paymentvalue === "telr_telrpayments") {
      const script = document.createElement("script");
      script.src = "https://secure.telr.com/jssdk/v2/telr_sdk.js";
      script.async = true;

      script.onload = () => {
        // Initialize Telr SDK here
        const telrSdk = window.telrSdk;
        var telr_params = {
          store_id: 20906,
          currency: "AED",
          test_mode: 1,
          callback: onTokenReceive,
        };
        telrSdk.init(telr_params);
      };
      document.head.appendChild(script);
    }
  }, [paymentvalue]);
  //-------- teller -------
  useEffect(() => {
    if (paymentvalue) {
      handleSetPaymentMethod(paymentvalue);
    }
  }, [paymentvalue]);

  const [generatePostPayTokenMutation] = useMutation(
    mutations.postPayMutation.POST_PAY_GENERATE_TOKEN
  );

  const [buildCcavenueCheckoutToken] = useMutation(
    mutations.postPayMutation.CCEVENUE_GENERATE_TOKEN,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store_code,
        },
      },
    }
  );

  const formik = useFormik({
    initialValues: Values,
    onSubmit: async (values: any) => {
      setProcedToPayToggle(true);
      const isLogin = localStorage.getItem("loggedInCust");
      if (isLogin === "" || !isLogin) {
        await setUestEmail({
          variables: {
            cart_id: quest_cart_id,
            email: values.bill_email,
          },
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store_code,
            },
          },
        });
      }
      addBillingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: values.bill_fname,
          lastname: values.bill_sname,
          company: "",
          street: values.bill_addr1,
          city: values.bill_city,
          region: selectedRegion?.bill_region,
          regionId: selectedRegion?.region_id,
          postCode: store_code === "in" ? values.pin_code : values.bill_zip,
          country_code: custCountry,
          telephone: values.bill_tel,
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
      await addShipingAddrMutation({
        variables: {
          cartId: quest_cart_id,
          firstname: values.bill_fname,
          lastname: values.bill_sname,
          company: "",
          street: values.bill_addr1,
          city: values.bill_city,
          region: selectedRegion?.bill_region,
          regionId: selectedRegion?.region_id,
          postCode: store_code === "in" ? values.pin_code : values.bill_zip,
          country_code: custCountry,
          telephone: values.bill_tel,
          save_in_address_book: true,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      });
      if (
        shippingMethods[0]?.carrier_code !== "" &&
        shippingMethods[0]?.carrier_code !== undefined &&
        shippingMethods[0]?.method_code !== undefined &&
        shippingMethods[0].method_code !== ""
      ) {
        await handleSetShippingMethod();
      }
      await handleSetPaymentMethod(paymentvalue);

      // await handlePlaceOrder();
      await placeOrderMutation({
        variables: {
          cartId: quest_cart_id,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store_code,
          },
        },
      })
        .then((result) => {
          localStorage.setItem(
            "orderId",
            result.data.placeOrder.order.order_number
          );
          localStorage.removeItem("quest_cart_id");
          if (paymentvalue === "cashondelivery") {
            navigate(`/${store_code}/ordersuccess`);
          }
          set_quest_cart_id("");
          setCartCount(0);
        })
        .catch((error) => {
          console.error("Error placing order:", error);
        });

      const newOrderId = localStorage.getItem("orderId");

      if (
        paymentvalue === "postpay" &&
        newOrderId !== null &&
        newOrderId !== ""
      ) {
        generatePostPayTokenMutation({
          variables: { orderId: newOrderId },
        })
          .then((res: any) => {
            window.location.href =
              res?.data?.createPostpayCheckout?.redirect_url;
            localStorage.setItem("orderId", "");
            set_quest_cart_id("");
            setCartCount(0);
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else if (
        paymentvalue === "ccavenue" &&
        newOrderId !== null &&
        newOrderId !== ""
      ) {
        buildCcavenueCheckoutToken({
          variables: { orderId: newOrderId },
        })
          .then((res: any) => {
            setCcevenueRes(res?.data?.buildCcavenueCheckout);
            localStorage.setItem("orderId", "");
            set_quest_cart_id("");
            setCartCount(0);
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else if (paymentvalue === "telr_telrpayments") {
        const formData = new FormData();
        // Append your data to the FormData object
        formData.append("telr_token", telerToken);
        formData.append("store_id", values?.store_id);
        formData.append("auth_key", values?.auth_key);
        formData.append("amount", values?.amount);
        formData.append("currency", values?.currency);
        formData.append("bill_fname", values?.bill_fname);
        formData.append("bill_sname", values?.bill_sname);
        formData.append("bill_addr1", values?.bill_addr1);
        formData.append("bill_addr2", values?.bill_addr2);
        formData.append("bill_city", values?.bill_city);
        formData.append("bill_region", values?.bill_region);
        formData.append("bill_zip", values?.bill_zip);
        formData.append("bill_country", selectedCountry);
        formData.append("bill_email", values?.bill_email);
        formData.append("bill_tel", values?.bill_tel);

        // Create a config object for headers
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios
          .post("https://react-in.jebelz.com/process.php", formData, config)
          .then((res: any) => {
            setSuccessUrl(res?.data?.redirect_link);
          });
      }
    },
  });

  const cardData = (
    <>
      <h1>hello</h1>
    </>
  );

  return (
    <>
      <div className="page-header">
        <div className="header-content">
          <Box sx={{ flexGrow: 1, mb: { xs: "5px", md: "0px" } }}>
            <AppBar position="static">
              <Toolbar sx={{ display: { md: "flex", xs: "block" } }}>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: { md: 0, xs: 2 },
                    width: "100%",
                    justifyContent:"center"
                  }}
                >
                  {/* <IconButton
                    className="toggle-nav-btn"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 1, display: { md: "none" }, color: "#fff" }}
                  >
                    {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
                  </IconButton> */}
                  <div className="logo-nav">
                    <div className="logo-container">
                      <Link
                        className="brand-link"
                        to="/"
                        style={{ height: "100%", display: "flex" }}
                      >
                        <img src={logoUrl} alt="React Logo" className="logo" />
                        {/* <img src={logoData?.header_logo_src} alt="React Logo" className="logo" /> */}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
        </div>
      </div>
      <div className="ckeckout-wrapper">
        <div className="checkout-cont-wrap">
          <form onSubmit={formik.handleSubmit}>
            <Container component="main" maxWidth="xl">
              {successUrl &&
                successUrl !== "" &&
                paymentvalue === "telr_telrpayments" && (
                  <Grid item xs={12}>
                    <iframe
                      src={successUrl}
                      title="success"
                      style={{
                        width: " 100%",
                        height: "404px",
                      }}
                    />
                  </Grid>
                )}
              {!successUrl &&
                successUrl === "" &&
                paymentvalue === "telr_telrpayments" &&
                ccevenueRes === "" && (
                  <Box
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ mb: 8, display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        component="h3"
                        variant="h4"
                        sx={{ fontWeight: 700, color: "#000" }}
                      >
                        {t("auth.checkout.checkout")}
                      </Typography>
                    </Box>
                    <Grid container spacing={4} className="directionrtl">
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        {loggedInToken !== "" &&
                        loggedInToken !== null &&
                        loggedInToken !== undefined ? (
                          custDetailsDataRes &&
                          custDetailsDataRes?.customer?.addresses.map(
                            (data: any, index: any) => {
                              return (
                                <Card
                                  key={index}
                                  sx={{
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    border: "1px solid #E0DEDE",
                                    mb: 3,
                                    p: 2,
                                  }}
                                >
                                  <CardHeader
                                    sx={{
                                      backgroundColor: "#eee",
                                      padding: "12px",
                                      "& .MuiTypography-root": {
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      },
                                    }}
                                    title={`${index + 1} Shipping Address`}
                                  />

                                  <CardContent
                                    sx={{
                                      px: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {showErrorAlert && (
                                      <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {resError}
                                      </Alert>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                        mb: 2,
                                      }}
                                    >
                                      <div className="address-content">
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.firstname}&nbsp;
                                          {data?.lastname}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.street[0]}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.city},
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.country_code}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          Mob&nbsp;:&nbsp;{data?.telephone}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleOpenModal(data?.id)
                                          }
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            handleSetShippingAdd(data?.id)
                                          }
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Ship Here
                                        </Button>
                                      </div>
                                    </Box>
                                    {custDetailsDataRes?.customer?.addresses
                                      .length -
                                      1 ===
                                      index && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "end",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Button
                                          onClick={() => handleOpenModal("")}
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            width: "fit-content",
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          New Address
                                        </Button>
                                      </Box>
                                    )}
                                  </CardContent>
                                </Card>
                              );
                            }
                          )
                        ) : (
                          <>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                mb: 3,
                                p: 2,
                              }}
                            >
                              <CardHeader
                                sx={{
                                  backgroundColor: "#eee",
                                  padding: "12px",
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  },
                                }}
                                title={t("auth.checkout.ship_addr_1")}
                              />

                              <CardContent sx={{ px: 0 }}>
                                {showErrorAlert && (
                                  <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {resError}
                                  </Alert>
                                )}
                                <Box
                                  component="form"
                                  noValidate
                                  sx={{ mt: 3 }}
                                  autoComplete="off"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} className="form-label">
                                      <label>
                                        {" "}
                                        {t("auth.checkout.signin")}
                                      </label>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.email")}
                                      </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                      <TextField
                                        autoComplete="off"
                                        name="email"
                                        required
                                        fullWidth
                                        id="email"
                                        onChange={handleChange}
                                        placeholder={t("auth.checkout.email")}
                                        value={
                                          custDetailsDataRes
                                            ? custDetailsDataRes?.customer
                                                ?.email
                                            : formData.email
                                        }
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.password")}
                                      </InputLabel>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={9}
                                      position="relative"
                                    >
                                      <TextField
                                        autoComplete="off"
                                        name="password"
                                        type={showPwd ? "text" : "password"}
                                        required
                                        fullWidth
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={t(
                                          "auth.checkout.password"
                                        )}
                                        autoFocus
                                      />
                                      <span
                                        className="pwd_visiblity"
                                        onClick={() => setShowPwd(!showPwd)}
                                      >
                                        {showPwd ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}{" "}
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} sm={3}></Grid>
                                    <Grid item xs={12} sm={9}>
                                      <Link to="/login">
                                        <Button
                                          onClick={handleGenerateToken}
                                          variant="contained"
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 34px",
                                          }}
                                        >
                                          {t("auth.checkout.login")}
                                        </Button>
                                      </Link>
                                    </Grid>

                                    <Grid item xs={12} sm={9}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {/* <Link
                                        to={`/registration`}
                                        style={{ color: "#1082C2" }}
                                      >
                                        {t("auth.checkout.registration")}
                                      </Link>
                                      <Link
                                        to={`/forgotpassword`}
                                        style={{ color: "#1082C2" }}
                                      >
                                        {t("auth.checkout.forgot_password")}
                                      </Link> */}
                                        <div
                                          onClick={() => {
                                            setIsOpenRegister(true);
                                            setIsAnchor(true);
                                          }}
                                        >
                                          {t("auth.checkout.registration")}
                                        </div>
                                        {isOpenRegister && (
                                          <Modal
                                            selectedValue="register"
                                            open={isOpenRegister}
                                            onClose={(value: any) =>
                                              setIsOpenRegister(value)
                                            }
                                          />
                                        )}
                                        <div
                                          onClick={() =>
                                            setIsOpenFPwd(!isOpenFPwd)
                                          }
                                        >
                                          {t("auth.checkout.forgot_password")}
                                        </div>
                                        {isOpenFPwd && (
                                          <Modal
                                            selectedValue="forgotpwd"
                                            open={isOpenFPwd}
                                            onClose={(value: any) =>
                                              setIsOpenFPwd(value)
                                            }
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                p: 2,
                              }}
                            >
                              <CardContent sx={{ px: 0 }}>
                                <Box
                                  component="form"
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.f_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_fname"
                                        required
                                        fullWidth
                                        id="bill_fname"
                                        placeholder={t("auth.checkout.f_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_fname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.l_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_sname"
                                        required
                                        fullWidth
                                        id="bill_sname"
                                        placeholder={t("auth.checkout.l_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_sname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.street_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr1"
                                        name="bill_addr1"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr1}
                                        placeholder={t(
                                          "auth.checkout.street_add"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.ship_addr_2")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr2"
                                        name="bill_addr2"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr2}
                                        placeholder={t(
                                          "auth.checkout.ship_addr_2"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.city")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_city"
                                        id="bill_city"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_city}
                                        placeholder={t("auth.checkout.city")}
                                      />
                                    </Grid>
                                    {selectedCountryData?.available_regions
                                      ?.length > 0 ? (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            border: "1px solid #C6C3C3",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          <Select
                                            IconComponent={CustomSelectIcon}
                                            labelId="demo-simple-select-label"
                                            name="bill_region"
                                            id="bill_region"
                                            className="qty-select"
                                            placeholder="Select Region"
                                            onChange={(e: any) => {
                                              setSelectedRegion({
                                                bill_region:
                                                  e.target.value?.code,
                                                region_id: e.target.value?.id,
                                              });
                                            }}
                                            // value={selectedRegion?.bill_region}
                                            defaultValue={
                                              selectedRegion?.bill_region
                                            }
                                          >
                                            {selectedCountryData?.available_regions?.map(
                                              (region: any) => {
                                                return (
                                                  <MenuItem
                                                    key={region?.id}
                                                    value={region}
                                                  >
                                                    {region?.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <TextField
                                          autoComplete="off"
                                          required
                                          fullWidth
                                          name="bill_region"
                                          id="bill_region"
                                          onChange={(e: any) => {
                                            setSelectedRegion({
                                              bill_region: e.target.value?.code,
                                              region_id: e.target.value?.id,
                                            });
                                          }}
                                          defaultValue={
                                            selectedRegion.bill_region
                                          }
                                          placeholder={t(
                                            "auth.checkout.region"
                                          )}
                                        />
                                      </Grid>
                                    )}

                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.zip_code")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_zip"
                                        id="bill_zip"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_zip}
                                        placeholder={t(
                                          "auth.checkout.zip_code"
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.email_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_email"
                                        required
                                        fullWidth
                                        id="bill_email"
                                        placeholder={t(
                                          "auth.checkout.email_add"
                                        )}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_email}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.mob_no")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_tel"
                                        id="bill_tel"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_tel}
                                        placeholder={t("auth.checkout.mob_no")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                        id="demo-simple-select-label"
                                      >
                                        {t("auth.checkout.country")}
                                      </InputLabel>
                                      <FormControl
                                        fullWidth
                                        sx={{
                                          border: "1px solid #C6C3C3",
                                          borderRadius: "2px",
                                        }}
                                      >
                                        <Select
                                          IconComponent={CustomSelectIcon}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          className="qty-select"
                                          disabled={true}
                                          value={selectedCountry}
                                        >
                                          {countryRegRes?.countries &&
                                            countryRegRes?.countries.map(
                                              (option: any) => {
                                                return (
                                                  <MenuItem
                                                    key={
                                                      option?.two_letter_abbreviation
                                                    }
                                                    value={
                                                      option?.two_letter_abbreviation
                                                    }
                                                  >
                                                    {option?.full_name_english}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    {/* {store === "in" ? (
                                    <>
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.Pincode")}
                                        </InputLabel>
                                        <TextField
                                          autoComplete="off"
                                          name="pincode"
                                          required
                                          fullWidth
                                          id="pincode"
                                          value={
                                            defaultBilling
                                              ? defaultBilling[0]?.postcode
                                              : ""
                                          }
                                          placeholder={t(
                                            "auth.checkout.Pincode"
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.state")}
                                        </InputLabel>
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            border: "1px solid #C6C3C3",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          <Select
                                            IconComponent={CustomSelectIcon}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="qty-select"
                                            value={
                                              defaultBilling
                                                ? defaultBilling[0]?.region
                                                    ?.region
                                                : custState
                                            }
                                            onChange={handledCustState}
                                          >
                                            {defaultBillingState &&
                                              defaultBillingState?.map(
                                                (option: any) => (
                                                  <MenuItem
                                                    key={option?.code}
                                                    value={option?.name}
                                                  >
                                                    {option?.name}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4} md={4} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.payment_method")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <FormControl fullWidth>
                                {paymentvalue !== undefined ? (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    sx={{ gap: "15px" }}
                                    className="payment-method-box"
                                    value={paymentvalue}
                                    onChange={handlePaymentChange}
                                  >
                                    {paymentMethodRes &&
                                      paymentMethodRes?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <>
                                              <FormControlLabel
                                                key={index}
                                                value={
                                                  paymentMethodRes[index]?.code
                                                }
                                                control={
                                                  <Radio className="payment-radio" />
                                                }
                                                label={
                                                  paymentMethodRes[index]?.title
                                                }
                                                sx={{
                                                  backgroundColor: "#F1F3F6",
                                                  mx: 0,
                                                  border: "0.25px solid #000",
                                                  padding: "10px 20px",
                                                  color: "#B0B5B6",
                                                }}
                                              />
                                              {paymentMethodRes[index]?.code ==
                                                paymentvalue &&
                                                paymentMethodRes[index]
                                                  ?.code !==
                                                  "cashondelivery" && (
                                                  <Box>
                                                    {paymentvalue !==
                                                      "telr_telrpayments" && (
                                                      <h1>{paymentvalue}</h1>
                                                    )}
                                                    {!successUrl &&
                                                      successUrl === "" && (
                                                        <Grid item xs={12}>
                                                          <div id="telr_frame"></div>
                                                        </Grid>
                                                      )}
                                                  </Box>
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                  </RadioGroup>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.order_summary")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <Box sx={{ minWidth: 100 }}>
                                <TextField
                                  required
                                  fullWidth
                                  name="total-qty"
                                  placeholder={`${cartDetails?.items?.length} Item in cart`}
                                  type="text"
                                  id="total-qty"
                                  autoComplete="off"
                                  className="total-qty-box"
                                  disabled // Set the disabled prop to disable the TextField
                                />
                              </Box>

                              {cartDetails &&
                                cartDetails?.items.map((data: any) => (
                                  <div
                                    className="cart-content"
                                    style={{ padding: "15px 0px" }}
                                  >
                                    <Box sx={{ height: 100, width: 100 }}>
                                      <CardMedia
                                        component="img"
                                        sx={{ width: "100%", height: "100%" }}
                                        image={data?.product?.small_image?.url}
                                        alt="Live from space album cover"
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CardContent
                                        sx={{ flex: "1 0 auto", pt: 0 }}
                                      >
                                        <Typography
                                          gutterBottom
                                          component="div"
                                          variant="h5"
                                          sx={{
                                            fontSize: 18,
                                            color: "#252525",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {data?.product?.name}
                                        </Typography>
                                      </CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <div className="qty-box">
                                          <label>
                                            {t("auth.checkout.Qty")}
                                          </label>
                                          <Box sx={{ minWidth: 100 }}>
                                            <FormControl
                                              fullWidth
                                              sx={{
                                                border: "1px solid #C6C3C3",
                                                borderRadius: "2px",
                                              }}
                                            >
                                              <Select
                                                IconComponent={CustomSelectIcon}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="qty-select"
                                                value={data?.quantity}
                                                label="Quantity"
                                                onChange={handledCheckQty}
                                                disabled={true}
                                                sx={{ backgroundColor: "#eee" }}
                                              >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </div>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                          sx={{
                                            fontSize: 20,
                                            color: "#252525",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {data?.prices?.price?.currency}
                                          {data?.prices?.price?.value}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          color: "#424141",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <Link to="/">
                                          {t("auth.checkout.view_details")}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </div>
                                ))}

                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "15px",
                                  py: 3,
                                }}
                              >
                                <div className="final-cart-details">
                                  <label className="item-lbl">
                                    {t("auth.checkout.cart_subtotal")}
                                  </label>
                                  <span className="item-val">
                                    {subtotal?.currency}
                                    {subTotalAmount}
                                  </span>
                                </div>
                              </Box>
                              <Divider />
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {t("auth.checkout.total")}
                                </label>
                                <span className="item-val">
                                  {cartDetails?.prices?.grand_total?.currency}
                                  {cartDetails?.prices?.grand_total?.value}
                                </span>
                              </div>
                              <Button
                                type="submit"
                                id="submit_form"
                                fullWidth
                                disabled={procedToPayToggle}
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize",
                                  mt: 2,
                                  color: "#fff",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  padding: "6px 34px",
                                }}
                              >
                                Proceed to Pay
                              </Button>
                            </Box>
                            <AddNewAddress
                              isOpen={open}
                              handleCloseModal={handleCloseModal}
                              isEdit={isEdit}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {(paymentvalue === "postpay" ||
                paymentvalue === "cashondelivery") &&
                !successUrl &&
                ccevenueRes === "" && (
                  <Box
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ mb: 8, display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        component="h3"
                        variant="h4"
                        sx={{ fontWeight: 700, color: "#000" }}
                      >
                        {t("auth.checkout.checkout")}
                      </Typography>
                    </Box>
                    <Grid container spacing={4} className="directionrtl">
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        {loggedInToken !== "" &&
                        loggedInToken !== null &&
                        loggedInToken !== undefined ? (
                          custDetailsDataRes &&
                          custDetailsDataRes?.customer?.addresses.map(
                            (data: any, index: any) => {
                              return (
                                <Card
                                  key={index}
                                  sx={{
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    border: "1px solid #E0DEDE",
                                    mb: 3,
                                    p: 2,
                                  }}
                                >
                                  <CardHeader
                                    sx={{
                                      backgroundColor: "#eee",
                                      padding: "12px",
                                      "& .MuiTypography-root": {
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      },
                                    }}
                                    title={`${index + 1} Shipping Address`}
                                  />

                                  <CardContent
                                    sx={{
                                      px: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {showErrorAlert && (
                                      <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {resError}
                                      </Alert>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                        mb: 2,
                                      }}
                                    >
                                      <div className="address-content">
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.firstname}&nbsp;
                                          {data?.lastname}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.street[0]}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.city},
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.country_code}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          Mob&nbsp;:&nbsp;{data?.telephone}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleOpenModal(data?.id)
                                          }
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            handleSetShippingAdd(data?.id)
                                          }
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Ship Here
                                        </Button>
                                      </div>
                                    </Box>
                                    {custDetailsDataRes?.customer?.addresses
                                      .length -
                                      1 ===
                                      index && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "end",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Button
                                          onClick={() => handleOpenModal("")}
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            width: "fit-content",
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          New Address
                                        </Button>
                                      </Box>
                                    )}
                                  </CardContent>
                                </Card>
                              );
                            }
                          )
                        ) : (
                          <>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                mb: 3,
                                p: 2,
                              }}
                            >
                              <CardHeader
                                sx={{
                                  backgroundColor: "#eee",
                                  padding: "12px",
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  },
                                }}
                                title={t("auth.checkout.ship_addr_1")}
                              />

                              <CardContent sx={{ px: 0 }}>
                                {showErrorAlert && (
                                  <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {resError}
                                  </Alert>
                                )}
                                <Box
                                  component="form"
                                  noValidate
                                  sx={{ mt: 3 }}
                                  autoComplete="off"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} className="form-label">
                                      <label>
                                        {" "}
                                        {t("auth.checkout.signin")}
                                      </label>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.email")}
                                      </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                      <TextField
                                        autoComplete="off"
                                        name="email"
                                        required
                                        fullWidth
                                        id="email"
                                        onChange={handleChange}
                                        placeholder={t("auth.checkout.email")}
                                        value={
                                          custDetailsDataRes
                                            ? custDetailsDataRes?.customer
                                                ?.email
                                            : formData.email
                                        }
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.password")}
                                      </InputLabel>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={9}
                                      position="relative"
                                    >
                                      <TextField
                                        autoComplete="off"
                                        name="password"
                                        type={showPwd ? "text" : "password"}
                                        required
                                        fullWidth
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={t(
                                          "auth.checkout.password"
                                        )}
                                        autoFocus
                                      />
                                      <span
                                        className="pwd_visiblity"
                                        onClick={() => setShowPwd(!showPwd)}
                                      >
                                        {showPwd ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}{" "}
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} sm={3}></Grid>
                                    <Grid item xs={12} sm={9}>
                                      <Link to="/login">
                                        <Button
                                          onClick={handleGenerateToken}
                                          variant="contained"
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 34px",
                                          }}
                                        >
                                          {t("auth.checkout.login")}
                                        </Button>
                                      </Link>
                                    </Grid>

                                    <Grid item xs={12} sm={9}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {/* <Link
                                        to={`/${store_code}/registration`}
                                        style={{ color: "#1082C2" }}
                                      >
                                        {t("auth.checkout.registration")}
                                      </Link>
                                      <Link
                                        to={`/${store_code}/forgotpassword`}
                                        style={{ color: "#1082C2" }}
                                      >
                                        {t("auth.checkout.forgot_password")}
                                      </Link> */}
                                        <div
                                          onClick={() => {
                                            setIsOpenRegister(true);
                                            setIsAnchor(true);
                                          }}
                                        >
                                          {t("auth.checkout.registration")}
                                        </div>
                                        {isOpenRegister && (
                                          <Modal
                                            selectedValue="register"
                                            open={isOpenRegister}
                                            onClose={(value: any) =>
                                              setIsOpenRegister(value)
                                            }
                                          />
                                        )}
                                        <div
                                          onClick={() =>
                                            setIsOpenFPwd(!isOpenFPwd)
                                          }
                                        >
                                          {t("auth.checkout.forgot_password")}
                                        </div>
                                        {isOpenFPwd && (
                                          <Modal
                                            selectedValue="forgotpwd"
                                            open={isOpenFPwd}
                                            onClose={(value: any) =>
                                              setIsOpenFPwd(value)
                                            }
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                p: 2,
                              }}
                            >
                              <CardContent sx={{ px: 0 }}>
                                <Box
                                  component="form"
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.f_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_fname"
                                        required
                                        fullWidth
                                        id="bill_fname"
                                        placeholder={t("auth.checkout.f_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_fname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.l_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_sname"
                                        required
                                        fullWidth
                                        id="bill_sname"
                                        placeholder={t("auth.checkout.l_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_sname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.street_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr1"
                                        name="bill_addr1"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr1}
                                        placeholder={t(
                                          "auth.checkout.street_add"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.ship_addr_2")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr2"
                                        name="bill_addr2"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr2}
                                        placeholder={t(
                                          "auth.checkout.ship_addr_2"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.city")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_city"
                                        id="bill_city"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_city}
                                        placeholder={t("auth.checkout.city")}
                                      />
                                    </Grid>
                                    {selectedCountryData?.available_regions
                                      ?.length > 0 ? (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            border: "1px solid #C6C3C3",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          <Select
                                            IconComponent={CustomSelectIcon}
                                            labelId="demo-simple-select-label"
                                            name="bill_region"
                                            id="bill_region"
                                            className="qty-select"
                                            placeholder={t(
                                              "auth.checkout.region"
                                            )}
                                            onChange={(e: any) => {
                                              setSelectedRegion({
                                                bill_region:
                                                  e.target.value?.code,
                                                region_id: e.target.value?.id,
                                              });
                                            }}
                                            // value={selectedRegion?.bill_region}
                                            defaultValue={
                                              selectedRegion?.bill_region
                                            }
                                          >
                                            {selectedCountryData?.available_regions?.map(
                                              (region: any) => {
                                                return (
                                                  <MenuItem
                                                    key={region?.id}
                                                    value={region}
                                                  >
                                                    {region?.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <TextField
                                          autoComplete="off"
                                          required
                                          fullWidth
                                          name="bill_region"
                                          id="bill_region"
                                          onChange={(e: any) => {
                                            setSelectedRegion({
                                              bill_region: e.target.value,
                                              region_id: 0,
                                            });
                                          }}
                                          defaultValue={
                                            selectedRegion.bill_region
                                          }
                                          placeholder={t(
                                            "auth.checkout.region"
                                          )}
                                        />
                                      </Grid>
                                    )}
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.zip_code")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_zip"
                                        id="bill_zip"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_zip}
                                        placeholder={t(
                                          "auth.checkout.zip_code"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.email_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_email"
                                        required
                                        fullWidth
                                        id="bill_email"
                                        placeholder={t(
                                          "auth.checkout.email_add"
                                        )}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_email}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.mob_no")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_tel"
                                        id="bill_tel"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_tel}
                                        placeholder={t("auth.checkout.mob_no")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                        id="demo-simple-select-label"
                                      >
                                        {t("auth.checkout.country")}
                                      </InputLabel>
                                      <FormControl
                                        fullWidth
                                        sx={{
                                          border: "1px solid #C6C3C3",
                                          borderRadius: "2px",
                                        }}
                                      >
                                        <Select
                                          IconComponent={CustomSelectIcon}
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          className="qty-select"
                                          disabled={true}
                                          value={selectedCountry}
                                        >
                                          {countryRegRes?.countries &&
                                            countryRegRes?.countries.map(
                                              (option: any) => {
                                                return (
                                                  <MenuItem
                                                    key={
                                                      option?.two_letter_abbreviation
                                                    }
                                                    value={
                                                      option?.two_letter_abbreviation
                                                    }
                                                  >
                                                    {option?.full_name_english}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4} md={4} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.payment_method")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <FormControl fullWidth>
                                {paymentvalue !== undefined ? (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    sx={{ gap: "15px" }}
                                    className="payment-method-box"
                                    value={paymentvalue}
                                    onChange={handlePaymentChange}
                                  >
                                    {paymentMethodRes &&
                                      paymentMethodRes?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <>
                                              <FormControlLabel
                                                key={index}
                                                value={
                                                  paymentMethodRes[index]?.code
                                                }
                                                control={
                                                  <Radio className="payment-radio" />
                                                }
                                                label={
                                                  paymentMethodRes[index]?.title
                                                }
                                                sx={{
                                                  backgroundColor: "#F1F3F6",
                                                  mx: 0,
                                                  border: "0.25px solid #000",
                                                  padding: "10px 20px",
                                                  color: "#B0B5B6",
                                                }}
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                  </RadioGroup>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={4} md={4} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.order_summary")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <Box sx={{ minWidth: 100 }}>
                                <TextField
                                  required
                                  fullWidth
                                  name="total-qty"
                                  placeholder={`${cartDetails?.items?.length} Item in cart`}
                                  type="text"
                                  id="total-qty"
                                  autoComplete="off"
                                  className="total-qty-box"
                                  disabled // Set the disabled prop to disable the TextField
                                />
                              </Box>

                              {cartDetails &&
                                cartDetails?.items.map((data: any) => (
                                  <div
                                    className="cart-content"
                                    style={{ padding: "15px 0px" }}
                                  >
                                    <Box sx={{ height: 100, width: 100 }}>
                                      <CardMedia
                                        component="img"
                                        sx={{ width: "100%", height: "100%" }}
                                        image={data?.product?.small_image?.url}
                                        alt="Live from space album cover"
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CardContent
                                        sx={{ flex: "1 0 auto", pt: 0 }}
                                      >
                                        <Typography
                                          gutterBottom
                                          component="div"
                                          variant="h5"
                                          sx={{
                                            fontSize: 18,
                                            color: "#252525",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {data?.product?.name}
                                        </Typography>
                                      </CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <div className="qty-box">
                                          <label>
                                            {t("auth.checkout.Qty")}
                                          </label>
                                          <Box sx={{ minWidth: 100 }}>
                                            <FormControl
                                              fullWidth
                                              sx={{
                                                border: "1px solid #C6C3C3",
                                                borderRadius: "2px",
                                              }}
                                            >
                                              <Select
                                                IconComponent={CustomSelectIcon}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="qty-select"
                                                value={data?.quantity}
                                                label="Quantity"
                                                onChange={handledCheckQty}
                                                disabled={true}
                                                sx={{ backgroundColor: "#eee" }}
                                              >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </div>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                          sx={{
                                            fontSize: 20,
                                            color: "#252525",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {data?.prices?.price?.currency}
                                          {data?.prices?.price?.value}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          color: "#424141",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <Link to="/">
                                          {t("auth.checkout.view_details")}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </div>
                                ))}

                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "15px",
                                  py: 3,
                                }}
                              >
                                <div className="final-cart-details">
                                  <label className="item-lbl">
                                    {t("auth.checkout.cart_subtotal")}
                                  </label>
                                  <span className="item-val">
                                    {subtotal?.currency}
                                    {subTotalAmount}
                                  </span>
                                </div>
                              </Box>
                              <Divider />
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {t("auth.checkout.total")}
                                </label>
                                <span className="item-val">
                                  {cartDetails?.prices?.grand_total?.currency}
                                  {cartDetails?.prices?.grand_total?.value}
                                </span>
                              </div>
                              <Button
                                type="submit"
                                id="submit_form"
                                fullWidth
                                disabled={procedToPayToggle}
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize",
                                  mt: 2,
                                  color: "#fff",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  padding: "6px 34px",
                                }}
                              >
                                {paymentvalue === "cashondelivery"
                                  ? "Plcae Order"
                                  : "Proceed to Pay"}
                              </Button>
                            </Box>
                            <AddNewAddress
                              isOpen={open}
                              handleCloseModal={handleCloseModal}
                              isEdit={isEdit}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {paymentvalue === "ccavenue" &&
                !successUrl &&
                ccevenueRes === "" && (
                  <Box
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ mb: 8, display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        component="h3"
                        variant="h4"
                        sx={{ fontWeight: 700, color: "#000" }}
                      >
                        {t("auth.checkout.checkout")}
                      </Typography>
                    </Box>
                    <Grid container spacing={4} className="directionrtl">
                      <Grid item xs={12} md={6} lg={4} xl={4}>
                        {loggedInToken !== "" &&
                        loggedInToken !== null &&
                        loggedInToken !== undefined ? (
                          custDetailsDataRes &&
                          custDetailsDataRes?.customer?.addresses.map(
                            (data: any, index: any) => {
                              return (
                                <Card
                                  key={index}
                                  sx={{
                                    maxWidth: "100%",
                                    boxShadow: "none",
                                    border: "1px solid #E0DEDE",
                                    mb: 3,
                                    p: 2,
                                  }}
                                >
                                  <CardHeader
                                    sx={{
                                      backgroundColor: "#eee",
                                      padding: "12px",
                                      "& .MuiTypography-root": {
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      },
                                    }}
                                    title={`${index + 1} Shipping Address`}
                                  />

                                  <CardContent
                                    sx={{
                                      px: 0,
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {showErrorAlert && (
                                      <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {resError}
                                      </Alert>
                                    )}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "end",
                                        mb: 2,
                                      }}
                                    >
                                      <div className="address-content">
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.firstname}&nbsp;
                                          {data?.lastname}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.street[0]}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.city},
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          {data?.country_code}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                          gutterBottom
                                          sx={{ fontSize: 18, fontWeight: 400 }}
                                        >
                                          Mob&nbsp;:&nbsp;{data?.telephone}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleOpenModal(data?.id)
                                          }
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            handleSetShippingAdd(data?.id)
                                          }
                                          disableElevation
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          Ship Here
                                        </Button>
                                      </div>
                                    </Box>
                                    {custDetailsDataRes?.customer?.addresses
                                      .length -
                                      1 ===
                                      index && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "end",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Button
                                          onClick={() => handleOpenModal("")}
                                          variant="contained"
                                          disableElevation
                                          sx={{
                                            width: "fit-content",
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 15px",
                                          }}
                                        >
                                          New Address
                                        </Button>
                                      </Box>
                                    )}
                                  </CardContent>
                                </Card>
                              );
                            }
                          )
                        ) : (
                          <>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                mb: 3,
                                p: 2,
                              }}
                            >
                              <CardHeader
                                sx={{
                                  backgroundColor: "#eee",
                                  padding: "12px",
                                  "& .MuiTypography-root": {
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  },
                                }}
                                title={t("auth.checkout.ship_addr_1")}
                              />

                              <CardContent sx={{ px: 0 }}>
                                {showErrorAlert && (
                                  <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {resError}
                                  </Alert>
                                )}
                                <Box
                                  component="form"
                                  noValidate
                                  sx={{ mt: 3 }}
                                  autoComplete="off"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} className="form-label">
                                      <label>
                                        {" "}
                                        {t("auth.checkout.signin")}
                                      </label>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.email")}
                                      </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                      <TextField
                                        autoComplete="off"
                                        name="email"
                                        required
                                        fullWidth
                                        id="email"
                                        onChange={handleChange}
                                        placeholder={t("auth.checkout.email")}
                                        value={
                                          custDetailsDataRes
                                            ? custDetailsDataRes?.customer
                                                ?.email
                                            : formData.email
                                        }
                                        autoFocus
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <InputLabel htmlFor="input-field">
                                        {t("auth.checkout.password")}
                                      </InputLabel>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={9}
                                      position="relative"
                                    >
                                      <TextField
                                        autoComplete="off"
                                        name="password"
                                        type={showPwd ? "text" : "password"}
                                        required
                                        fullWidth
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={t(
                                          "auth.checkout.password"
                                        )}
                                        autoFocus
                                      />
                                      <span
                                        className="pwd_visiblity"
                                        onClick={() => setShowPwd(!showPwd)}
                                      >
                                        {showPwd ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}{" "}
                                      </span>
                                    </Grid>
                                    <Grid item xs={12} sm={3}></Grid>
                                    <Grid item xs={12} sm={9}>
                                      <Link to="/login">
                                        <Button
                                          onClick={handleGenerateToken}
                                          variant="contained"
                                          sx={{
                                            textTransform: "capitalize",
                                            color: "#fff",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            padding: "6px 34px",
                                          }}
                                        >
                                          {t("auth.checkout.login")}
                                        </Button>
                                      </Link>
                                    </Grid>

                                    <Grid item xs={12} sm={9}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          onClick={() => {
                                            setIsOpenRegister(true);
                                            setIsAnchor(true);
                                          }}
                                        >
                                          {t("auth.checkout.registration")}
                                        </div>
                                        {isOpenRegister && (
                                          <Modal
                                            selectedValue="register"
                                            open={isOpenRegister}
                                            onClose={(value: any) =>
                                              setIsOpenRegister(value)
                                            }
                                          />
                                        )}
                                        <div
                                          onClick={() =>
                                            setIsOpenFPwd(!isOpenFPwd)
                                          }
                                        >
                                          {t("auth.checkout.forgot_password")}
                                        </div>
                                        {isOpenFPwd && (
                                          <Modal
                                            selectedValue="forgotpwd"
                                            open={isOpenFPwd}
                                            onClose={(value: any) =>
                                              setIsOpenFPwd(value)
                                            }
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                            <Card
                              sx={{
                                maxWidth: "100%",
                                boxShadow: "none",
                                border: "1px solid #E0DEDE",
                                p: 2,
                              }}
                            >
                              <CardContent sx={{ px: 0 }}>
                                <Box
                                  component="form"
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.f_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_fname"
                                        required
                                        fullWidth
                                        id="bill_fname"
                                        placeholder={t("auth.checkout.f_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_fname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.l_name")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_sname"
                                        required
                                        fullWidth
                                        id="bill_sname"
                                        placeholder={t("auth.checkout.l_name")}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_sname}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.street_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr1"
                                        name="bill_addr1"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr1}
                                        placeholder={t(
                                          "auth.checkout.street_add"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.ship_addr_2")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        id="bill_addr2"
                                        name="bill_addr2"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_addr2}
                                        placeholder={t(
                                          "auth.checkout.ship_addr_2"
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.city")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_city"
                                        id="bill_city"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_city}
                                        placeholder={t("auth.checkout.city")}
                                      />
                                    </Grid>
                                    {selectedCountryData?.available_regions
                                      ?.length > 0 ? (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            border: "1px solid #C6C3C3",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          <Select
                                            IconComponent={CustomSelectIcon}
                                            labelId="demo-simple-select-label"
                                            name="bill_region"
                                            id="bill_region"
                                            className="qty-select"
                                            placeholder={t(
                                              "auth.checkout.region"
                                            )}
                                            onChange={(e: any) => {
                                              setSelectedRegion({
                                                bill_region:
                                                  e.target.value?.code,
                                                region_id: e.target.value?.id,
                                              });
                                            }}
                                            // value={selectedRegion?.bill_region}
                                            defaultValue={
                                              selectedRegion?.bill_region
                                            }
                                          >
                                            {selectedCountryData?.available_regions?.map(
                                              (region: any) => {
                                                return (
                                                  <MenuItem
                                                    key={region?.id}
                                                    value={region}
                                                  >
                                                    {region?.name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.region")}
                                        </InputLabel>
                                        <TextField
                                          autoComplete="off"
                                          required
                                          fullWidth
                                          name="bill_region"
                                          id="bill_region"
                                          onChange={(e: any) => {
                                            setSelectedRegion({
                                              bill_region: e.target.value?.code,
                                              region_id: 0,
                                            });
                                          }}
                                          // value={selectedRegion?.bill_region}
                                          defaultValue={
                                            selectedRegion?.bill_region
                                          }
                                          placeholder={t(
                                            "auth.checkout.region"
                                          )}
                                        />
                                      </Grid>
                                    )}
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.email_add")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        name="bill_email"
                                        required
                                        fullWidth
                                        id="bill_email"
                                        placeholder={t(
                                          "auth.checkout.email_add"
                                        )}
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_email}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InputLabel
                                        htmlFor="input-field"
                                        sx={{ mb: 1 }}
                                      >
                                        {t("auth.checkout.mob_no")}
                                      </InputLabel>
                                      <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        name="bill_tel"
                                        id="bill_tel"
                                        onChange={formik.handleChange}
                                        value={formik.values.bill_tel}
                                        placeholder={t("auth.checkout.mob_no")}
                                      />
                                    </Grid>
                                    {store_code === "in" ? (
                                      <>
                                        <Grid item xs={12}>
                                          <InputLabel
                                            htmlFor="input-field"
                                            sx={{ mb: 1 }}
                                          >
                                            {t("auth.checkout.Pincode")}
                                          </InputLabel>
                                          <TextField
                                            autoComplete="off"
                                            name="pin_code"
                                            required
                                            fullWidth
                                            id="pin_code"
                                            onChange={formik.handleChange}
                                            value={formik.values.pin_code}
                                            placeholder={t(
                                              "auth.checkout.Pincode"
                                            )}
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <InputLabel
                                            htmlFor="input-field"
                                            sx={{ mb: 1 }}
                                            id="demo-simple-select-label"
                                          >
                                            {t("auth.checkout.country")}
                                          </InputLabel>
                                          <FormControl
                                            fullWidth
                                            sx={{
                                              border: "1px solid #C6C3C3",
                                              borderRadius: "2px",
                                            }}
                                          >
                                            <Select
                                              IconComponent={CustomSelectIcon}
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              className="qty-select"
                                              disabled={true}
                                              value={selectedCountry}
                                            >
                                              {countryRegRes?.countries &&
                                                countryRegRes?.countries.map(
                                                  (option: any) => {
                                                    return (
                                                      <MenuItem
                                                        key={
                                                          option?.two_letter_abbreviation
                                                        }
                                                        value={
                                                          option?.two_letter_abbreviation
                                                        }
                                                      >
                                                        {
                                                          option?.full_name_english
                                                        }
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                        <InputLabel
                                          htmlFor="input-field"
                                          sx={{ mb: 1 }}
                                        >
                                          {t("auth.checkout.state")}
                                        </InputLabel>
                                        <FormControl
                                          fullWidth
                                          sx={{
                                            border: "1px solid #C6C3C3",
                                            borderRadius: "2px",
                                          }}
                                        >
                                          <Select
                                            IconComponent={CustomSelectIcon}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="qty-select"
                                            value={
                                              defaultBilling
                                                ? defaultBilling[0]?.region
                                                    ?.region
                                                : custState
                                            }
                                            onChange={handledCustState}
                                          >
                                            {defaultBillingState &&
                                              defaultBillingState?.map(
                                                (option: any) => (
                                                  <MenuItem
                                                    key={option?.code}
                                                    value={option?.name}
                                                  >
                                                    {option?.name}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                      </Grid> */}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Box>
                              </CardContent>
                            </Card>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.payment_method")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <FormControl fullWidth>
                                {paymentvalue !== undefined ? (
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    sx={{ gap: "15px" }}
                                    className="payment-method-box"
                                    value={paymentvalue}
                                    onChange={handlePaymentChange}
                                  >
                                    {paymentMethodRes &&
                                      paymentMethodRes?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <>
                                              <FormControlLabel
                                                key={index}
                                                value={
                                                  paymentMethodRes[index]?.code
                                                }
                                                control={
                                                  <Radio className="payment-radio" />
                                                }
                                                label={
                                                  paymentMethodRes[index]?.title
                                                }
                                                sx={{
                                                  backgroundColor: "#F1F3F6",
                                                  mx: 0,
                                                  border: "0.25px solid #000",
                                                  padding: "10px 20px",
                                                  color: "#B0B5B6",
                                                }}
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                  </RadioGroup>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} xl={4}>
                        <Card
                          sx={{
                            maxWidth: "100%",
                            boxShadow: "none",
                            border: "1px solid #E0DEDE",
                            p: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: "#eee",
                              padding: "12px",
                              "& .MuiTypography-root": {
                                fontSize: "16px",
                                fontWeight: "600",
                              },
                            }}
                            title={t("auth.checkout.order_summary")}
                          />

                          <CardContent sx={{ px: 0 }}>
                            <Box>
                              <Box sx={{ minWidth: 100 }}>
                                <TextField
                                  required
                                  fullWidth
                                  name="total-qty"
                                  placeholder={`${cartDetails?.items?.length} Item in cart`}
                                  type="text"
                                  id="total-qty"
                                  autoComplete="off"
                                  className="total-qty-box"
                                  disabled // Set the disabled prop to disable the TextField
                                />
                              </Box>

                              {cartDetails &&
                                cartDetails?.items.map((data: any) => (
                                  <div
                                    className="cart-content"
                                    style={{ padding: "15px 0px" }}
                                  >
                                    <Box sx={{ height: 100, width: 100 }}>
                                      <CardMedia
                                        component="img"
                                        sx={{ width: "100%", height: "100%" }}
                                        image={data?.product?.small_image?.url}
                                        alt="Live from space album cover"
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CardContent
                                        sx={{ flex: "1 0 auto", pt: 0 }}
                                      >
                                        <Typography
                                          gutterBottom
                                          component="div"
                                          variant="h5"
                                          sx={{
                                            fontSize: 18,
                                            color: "#252525",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {data?.product?.name}
                                        </Typography>
                                      </CardContent>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          pl: 1,
                                          pb: 1,
                                        }}
                                      >
                                        <div className="qty-box">
                                          <label>
                                            {t("auth.checkout.Qty")}
                                          </label>
                                          <Box sx={{ minWidth: 100 }}>
                                            <FormControl
                                              fullWidth
                                              sx={{
                                                border: "1px solid #C6C3C3",
                                                borderRadius: "2px",
                                              }}
                                            >
                                              <Select
                                                IconComponent={CustomSelectIcon}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                className="qty-select"
                                                value={data?.quantity}
                                                label="Quantity"
                                                onChange={handledCheckQty}
                                                disabled={true}
                                                sx={{ backgroundColor: "#eee" }}
                                              >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </div>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                          sx={{
                                            fontSize: 20,
                                            color: "#252525",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {data?.prices?.price?.currency}
                                          {data?.prices?.price?.value}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          color: "#424141",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <Link to="/">
                                          {t("auth.checkout.view_details")}
                                        </Link>
                                      </Box>
                                    </Box>
                                  </div>
                                ))}

                              <Divider />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "15px",
                                  py: 3,
                                }}
                              >
                                <div className="final-cart-details">
                                  <label className="item-lbl">
                                    {t("auth.checkout.cart_subtotal")}
                                  </label>
                                  <span className="item-val">
                                    {subtotal?.currency}
                                    {subTotalAmount}
                                  </span>
                                </div>
                              </Box>
                              <Divider />
                              <div className="final-cart-details">
                                <label className="item-lbl">
                                  {t("auth.checkout.total")}
                                </label>
                                <span className="item-val">
                                  {cartDetails?.prices?.grand_total?.currency}
                                  {cartDetails?.prices?.grand_total?.value}
                                </span>
                              </div>
                              <Button
                                type="submit"
                                id="submit_form"
                                fullWidth
                                disabled={procedToPayToggle}
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize",
                                  mt: 2,
                                  color: "#fff",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  padding: "6px 34px",
                                }}
                              >
                                Proceed to Pay
                              </Button>
                            </Box>
                            <AddNewAddress
                              isOpen={open}
                              handleCloseModal={handleCloseModal}
                              isEdit={isEdit}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {ccevenueRes && <CCAvenueForm data={ccevenueRes} />}
            </Container>
          </form>
        </div>
      </div>
    </>
  );
}
export default Checkout;
