import { gql } from "@apollo/client/";


export const POST_PAY_GENERATE_TOKEN = gql`
mutation createPostpayCheckoutToken($orderId: String!) {
    createPostpayCheckout(
        orderId: $orderId
    ) {
        status
        token
        expires
        redirect_url
        reference
        msg
    }
  }`;

export const CCEVENUE_GENERATE_TOKEN = gql`
mutation buildCcavenueCheckoutToken($orderId: String!) {
    buildCcavenueCheckout(
        orderId: $orderId
    ) 
    {
        fields {
            integration_type
            access_code
            encRequest
        }
        url
        status
        msg
    }
  }`;