import { gql } from "@apollo/client";

export const GET_CUSTOMER_INFO = gql`
  query getCustInfo {
    customer {
      firstname
      lastname
      suffix
      email
      default_billing
      default_shipping
      addresses {
          id
        firstname
        lastname
        company
        street
        city
        region {
          region_code
          region
        }
        postcode
        country_code
        telephone
        default_shipping
        default_billing
      }
    }
  }
`;
export const GET_CUSTOMER_CART_QUERY = gql`
  query GetCustomerCartQuery {
    customerCart {
      id
    }
  }
`;

export const GET_CUSTOMER_ORDER_LIST = gql`
  query GetCustomerOrderList{
    customer {
      firstname
      lastname
      email
      orders(
        filter: {
          
        }) {
        items {
          number
          order_date
          status
          total {
              base_grand_total {
                  value
                  currency
              }
              grand_total {
                  value
                  currency
              }
          }
          shipping_method
          payment_methods {
              name
          }
          shipments{
              comments {
                  message
              }
              items {
                  order_item{
                      quantity_invoiced
                      quantity_shipped
                      quantity_canceled
                      product_name
                      product_sale_price {
                          value
                      }
                      product_sku
                      
                  }
              }
          }
          items {
            product_name
            product_sku
            
            product_type
            quantity_ordered
            quantity_invoiced
            quantity_shipped
            quantity_refunded
            product_sale_price {
                value
            }
            ...on BundleOrderItem {
              bundle_options {
                id
                label
                values {
                  id
                  product_name
                  product_sku
                  quantity
                  price {
                    value
                    currency
                  }
                }
              }
            }
            ...on DownloadableOrderItem {
              downloadable_links {
                uid
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOMER_ORDER_DETAILS_QUERY = gql`
  query GetCustomerOrderDetails($orderNumber: String!) {
    customer {
    firstname
    lastname
    email
    orders(
      filter: {
        number: {
            eq: $orderNumber
        }
      }) {
      items {
        number
        order_date
        status
        total {
            base_grand_total {
                value
                currency
            }
            grand_total {
                value
                currency
            }
        }
        shipping_method
        payment_methods {
            name
        }
        shipments{
            comments {
                message
            }
            items {
                order_item{
                    quantity_invoiced
                    quantity_shipped
                    quantity_canceled
                    product_name
                    product_sale_price {
                        value
                        currency
                    }
                    product_sku
                    
                }
            }
        }
        shipping_address {
            firstname
            lastname
            street
            city
            region 
            postcode
            country_code
            telephone
        }
        billing_address {
            firstname
            lastname
            street
            city
            region 
            postcode
            country_code
            telephone
        }
        items {
          product_name
          product_sku
          
          product_type
          quantity_ordered
          quantity_invoiced
          quantity_shipped
          quantity_refunded
          product_sale_price {
              value
          }
          ...on BundleOrderItem {
            bundle_options {
              id
              label
              values {
                id
                product_name
                product_sku
                quantity
                price {
                  value
                  currency
                }
              }
            }
          }
          ...on DownloadableOrderItem {
            downloadable_links {
              uid
              title
            }
          }
        }
      }
    }
  }
  }
`;
export const GET_RECENT_CUSTOMER_ORDER = gql`
query GetRecentOrder($currentPage: Int!, $pageSize: Int!) {
  customer {
    firstname
    lastname
    email
    orders(currentPage: $currentPage, pageSize: $pageSize) {
      items {
        number
        order_date
        status
        total {
          base_grand_total {
            value
          }
          grand_total {
            value
          }
        }
        shipping_method
        payment_methods {
          name
        }
        shipments {
          comments {
            message
          }
          items {
            order_item {
              quantity_invoiced
              quantity_shipped
              quantity_canceled
              product_name
              product_sale_price {
                value
              }
              product_sku
            }
          }
        }
        items {
          product_name
          product_sku
          product_type
          quantity_ordered
          quantity_invoiced
          quantity_shipped
          quantity_refunded
          product_sale_price {
            value
          }
          ...on BundleOrderItem {
            bundle_options {
              id
              label
              values {
                id
                product_name
                product_sku
                quantity
                price {
                  value
                  currency
                }
              }
            }
          }
          ...on DownloadableOrderItem {
            downloadable_links {
              uid
              title
            }
          }
        }
      }
    }
  }
}
`;