import React, { useEffect, useState } from "react";
import LoginHeader from "../Layout/login-header";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import orderSuccess from "../../../assets/image/order-success.png";
import CardMedia from "@mui/material/CardMedia";
import { Link, useLocation } from "react-router-dom";

function OrderSuccess() {
  const location = useLocation();
  const order_no = localStorage.getItem("orderId");

  return (
    <div className="login-wrapper">
      <div className="order-success-wrap">
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12}>
            <Box sx={{ width: 980, m: "auto" }}>
              <Card
                className="order-thank-card"
                sx={{
                  overflow: "inherit",
                  boxShadow: "none",
                  textAlign: "center",
                }}
              >
                <CardContent sx={{ width: 550, margin: "auto" }}>
                  <Box sx={{ height: 64, width: 64, margin: "auto" }}>
                    <CardMedia
                      component="img"
                      image={orderSuccess}
                      alt="Order success "
                    />
                  </Box>

                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontSize: 30, fontWeight: 700 }}
                    color="#000"
                    gutterBottom
                  >
                    Thanks You For Tour Purchase
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 400, lineHeight: "30px" }}
                    color="#000"
                  >
                    Your Order no is {order_no ? order_no: ""}
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontSize: 18, fontWeight: 400, lineHeight: "30px" }}
                    color="#000"
                  >
                    We'll email you an order confirmation with details and
                    tracking info.
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link to="/">
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        textTransform: "unset",
                        color: "#fff",
                        backgroundColor: "#10CDF2",
                        padding: "8px 20px",
                      }}
                    >
                      Continue Shopping
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default OrderSuccess;
