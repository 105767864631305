import { gql } from "@apollo/client";


export const GUEST_CART_DATA = gql`
mutation guestCartData {
  createEmptyCart
  }
`;
export const LOGGUSER_CART_DATA = gql`
mutation logUserCartData {
  createEmptyCart
  }
`;


export const ADD_SIMPLE_CART_MUTATION= gql`
mutation postaddsimplecart($cartId: String!, $quantity: Float!, $sku: String!) {
  addProductsToCart(cartId: $cartId, cartItems: [{ quantity: $quantity, sku: $sku }]) {
    cart {
      items {
        product {
          name
          sku
        }
        quantity
      }
    }
    user_errors {
      code
      message
    }
  }
  }
`;
export const ADD_CONFIG_PRODUCTS_TO_CART_MUTATION = gql`
  mutation AddConfigurableProductsToCart($input: AddConfigurableProductsToCartInput!) {
    addConfigurableProductsToCart(input: $input) {
      cart {
        items {
          uid
          quantity
          product {
            name
            sku
          }
          ... on ConfigurableCartItem {
            configurable_options {
              option_label
            }
          }
        }
      }
    }
  }
`;
export const REMOVECART_MUTATION= gql`
mutation removeItemFromCart($cartId: String!, $cartItemId: Int!) {
  removeItemFromCart(
    input: {
      cart_id: $cartId,
      cart_item_id: $cartItemId
    }
  )
 {
  cart {
    items {
      id
      product {
        name
      }
      quantity
    }
    prices {
      grand_total{
        value
        currency
      }
    }
  }
 }
  }
`;

export const UPDATE_CART_ITEMS = gql`
  mutation UpdateCartItems($input: UpdateCartItemsInput!) {
    updateCartItems(input: $input) {
      cart {
        items {
          uid
          product {
            name
          }
          quantity
          prices {
            price {
              value
            }
            row_total {
              value
            }
            total_item_discount {
              value
            }
          }
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const SET_GUEST_EMAIL = gql`
mutation setGuestEmailOnCart($cart_id: String!, $email: String!) {
  setGuestEmailOnCart(
    input: {
      cart_id: $cart_id
      email:  $email
    }
  ) {
    cart {
      email
    }
  }
}
`;
