import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import Registration from "../../container/buyer/auth/registration";
import ForgotPassword from "../../container/buyer/auth/ForgotPassword";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import "./modal.scss";

function Modal(props: any) {
  const { onClose, selectedValue, open } = props;
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose(false);
  };

  const handleListItemClick = (value: any) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} className="register-modal">
      <div className="modal-header">
        <DialogTitle>
          {selectedValue === "register"
            ? t("auth.registration.create_new")
            : t("auth.forgotPwd.frg_pwd_title")}
        </DialogTitle>
        <CloseIcon onClick={handleClose} />
      </div>
      <List sx={{ pt: 0 }}>
        {selectedValue === "register" ? <Registration /> : <ForgotPassword />}
      </List>
    </Dialog>
  );
}

export default Modal;
