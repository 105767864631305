

import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  {
    countries {
      full_name_english
      full_name_locale
      three_letter_abbreviation
      two_letter_abbreviation
      available_regions {
        name
        id
        code
      }
    }
  }
`;