import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard";
import Checkout from "./auth/checkout";
import RegSuccess from "./auth/RegSuccess";

export type ICountryContext = {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
};

export const CountryContext = createContext({
  country: "ae_ar",
  setCountry: (country: string) => {},
  store: "",
  setStore: (store: string) => {},
  loading: true,
  setLoading: (loading: boolean) => {},
});
export const LoadingContext = createContext({
  Loading: true,
  setLoading: (loading: boolean) => {},
});

export const mainContext = createContext({
  cartData: [],
  setCartData: (data: any) => {},
  cartCount: 0,
  setCartCount: (count: any) => {},
  quest_cart_id: localStorage.getItem("quest_cart_id") || null,
  set_quest_cart_id: (id: any) => {},
});
function MainComponent() {
  const [country, setCountry] = useState("ae_ar");
  const [store, setStore] = useState("");
  const [loading, setLoading] = useState(true);
  const [onLoad, setOnLoad] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [quest_cart_id, set_quest_cart_id] = useState(
    localStorage.getItem("quest_cart_id") || null
  );

  const handleCountryChange = (store: any, val: any, load: boolean) => {
    setStore(store);
    setCountry(val);
    setOnLoad(load);
  };

  return (
    // <BrowserRouter basename="/jebelz-ui"></BrowserRouter>
    <CountryContext.Provider
      value={{ country, setCountry, store, setStore, loading, setLoading }}
    >
      <mainContext.Provider
        value={{
          cartData,
          setCartData,
          cartCount,
          setCartCount,
          quest_cart_id,
          set_quest_cart_id,
        }}
      >
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/regsuccess" element={<RegSuccess />} />
            {/* <Route path="/registration" element={<Registration />} /> */}
            <Route path="/*" element={<Dashboard />} />
            <Route path="/:country/checkout" element={<Checkout />} />
          </Routes>
        </BrowserRouter>
      </mainContext.Provider>
    </CountryContext.Provider>
  );
}

export default MainComponent;
