import React, { useContext, useEffect } from "react";
import { CountryContext } from "./MainComponent";
import Loading from "./Layout/loader"

const SubdomainRedirect = () => {
  const { store } = useContext(CountryContext);
  const host = window.location.hostname
  useEffect(() => {
    if (store === "in" && !host.includes("react-in")) {
      window.location.href = `http://react-in.jebelz.com/${store}`;
      // window.location.href = `/${store}`
    // } else if (store === "ae_en" && !host.includes("react-in")) {
    //   window.location.href = `https://react.jebelz.com/${store}`;
    } else if (store === "sa_ar" && !host.includes("react-sa")) {
      window.location.href = `https://react-sa.jebelz.com/${store}`;
    } else if (store === "ar" && !host.includes("react-om")) {
      window.location.href = `https://react-om.jebelz.com/${store}`;
    } else {
      window.location.href = `https://react.jebelz.com/${store}`;
    }
  }, [store]);

  return <><Loading /></>;
};

export default SubdomainRedirect;
