import React, { useEffect, useState } from "react";
import Sidebar from "../../seller/Layout/Sidebar";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});
interface Column {
  id: "order" | "date" | "shipto" | "ordertotal" | "status" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "order", label: "#Order", minWidth: 170 },
  { id: "date", label: "Date", minWidth: 100 },
  {
    id: "shipto",
    label: "Ship To",
    minWidth: 170,
    align: "right",
    //   format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: "ordertotal",
    label: "Order Total",
    minWidth: 170,
    align: "right",
    //   format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
    //   format: (value: number) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
    //   format: (value: number) => value.toFixed(2),
  },
];

interface Data {
  order: string;
  date: string;
  shipto: string;
  ordertotal: string;
  status: string;
  action: string;
}

function createData(
  order: string,
  date: string,
  shipto: string,
  ordertotal: string,
  status: string,
  action: string
): Data {
  // const density = population / size;
  return { order, date, shipto, ordertotal, status, action };
}

function MyOrderList() {
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let rows: any = [];
  let shipTo: any;
  let OrderTotal: any;
  let dateTimeString: any;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { loading: custOrderListloader, data: custOrderListres } = useQuery(
    queries.customerQueries.GET_CUSTOMER_ORDER_LIST,
    {
      // variables: { sku: params.sku },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  shipTo =
    custOrderListres?.customer?.firstname +
    " " +
    custOrderListres?.customer?.lastname;
  custOrderListres?.customer?.orders?.items.map((data: any) => {
    OrderTotal =
      data?.total?.grand_total?.currency +
      " " +
      data?.total?.grand_total?.value;
    const orderDate = data?.order_date;
    const parts = orderDate.split(" ");
    dateTimeString = parts[0];
    rows.push(
      createData(
        data?.number,
        dateTimeString,
        shipTo,
        OrderTotal,
        data?.status,
        data?.number
      )
    );
  });
  // rows = custOrderListres?.customer?.orders?.items;

  // rows.map((data:any)=>{
  //     rows = data;
  // })
  const renderViewOrderLink = (orderNumber: any) => {
    const orderLink = `order/${orderNumber}`;

    return (
      <Link to={orderLink}>{t("buyer_dashboard.myOrderList.view_order")}</Link>
    );
  };

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <TableContainer
            sx={{ maxHeight: 540 }}
            className="order-tbl-container"
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.number}
                        >
                          {columns.map((column) => {
                            const value = row[column?.id];
                            return (
                              <TableCell key={column?.id} align={column?.align}>
                                {/* {value} */}
                                {column.id === "action"
                                  ? renderViewOrderLink(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="">
              {custOrderListres?.customer?.orders?.items?.length}{" "}
              {t("buyer_dashboard.myOrderList.item")}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
export default MyOrderList;
